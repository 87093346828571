<kendo-tabstrip class="r-tabs mb-4 mt-4 col-md-12">
  <kendo-tabstrip-tab title="SMS"
    [selected]="true">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-gear me-1 icon fs-5"
      aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-6">
        <div class="d-flex flex-column">
          @if (form.controls.menu?.value) {
            <span>
              When caller <em>presses</em> digit
              <em [class]="'fa-duotone fa-circle-' + form.controls.digits!.value + ' fs-2'"
              style="--fa-secondary-color: #a8b2c2;"></em> ,
              they will be redirected to the <strong>{{ getMenuName() }}</strong> menu,
              which is selected in the Menus dropdown above ⬆️.
            </span>
          }
          @if (!form.controls.menu?.value) {
            <span>
              Please select a menu from the dropdown above ⬆️, so that when caller presses
              <em [class]="'fa-duotone fa-circle-' + form.controls.digits!.value + ' fs-2'"
              style="--fa-secondary-color: #a8b2c2;"></em> , they will redirected to the selected menu.
            </span>
          }
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title=" ">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-question me-1 icon fs-4"
      aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-6">
        <div class="d-flex flex-column">
          <p>
            The <em>Link</em> 🔗 feature on an IVR system allows callers to be redirected to different menu options based on their
            digit selection. For example, pressing 1 could direct callers to an English menu, while pressing 2 connects
            them
            to a Spanish menu, catering to diverse customer preferences.
          </p>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>


<div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
  <button kendoButton
    themeColor="primary"
    class="btn btn-light-primary btn-icon icon-35"
    (click)="save.emit(form)"
    title="Save">
    <em class="fa-duotone fa-floppy-disks"></em>
    <span class="ms-2 label7">Save</span>
  </button>
  <button kendoButton
    class="btn btn-light-danger btn-icon icon-35"
    (click)="delete.emit(form)"
    title="Delete">
    <em class="fa-duotone fa-trash-can"></em>
    <span class="ms-2 label7">Delete</span>
  </button>
</div>