import { FormArray, FormControl, FormGroup, UntypedFormArray } from "@angular/forms";
import { Menu } from "src/app/menus/models/menu";

export interface Company {
  name?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface Billing {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface Customer {
  _id?: string;
  first?: string;
  last?: string;
  company?: Company;
  forward?: boolean;
  forwardNumber?: string;
  numbers?: Number[];
  billing?: Billing;
  stripeId?: string
}

export class Number {
  _id?: string | null = null;
  number?: string | null = null;
  isDefault?: boolean | null = null;
  name?: string | null = null;
  sid?: string | null = null;
  customer?: any;
  menus?: Menu[] = [];
  provider?: Provider = Provider.Telnyx;
  openMenuId?: string;
  closedMenuId?: string;
  timeZone?: string;
  hours?: [
    {
      day?: string;
      open?: string;
      close?: string;
    }
  ];
  voice?: Voice;
}

export interface Voice {
  name: string;
  displayName: string;
  localName: string;
  shortName: string;
  gender: string;
  locale: string;
  localeName: string;
  styleList?: string[];
  sampleRateHertz: string;
  voiceType: string;
  status: string;
  wordsPerMinute: string;
}

export class IVR {
  _id?: string;
  forward?: boolean;
  forwardNumber?: string;
}

export interface AvailabilityForm {
  hours?: FormArray<FormGroup<HourForm>>;
  openMenuId: FormControl<string | null>;
  closedMenuId: FormControl<string | null>;
  timeZone: FormControl<string | null>;
}

export interface HourForm {
  _id: FormControl<string | null>;
  day: FormControl<string | null>;
  open: FormControl<string | null>;
  close: FormControl<string | null>;
  closed: FormControl<boolean | null>;
  isFirstDayOfGroup: FormControl<boolean | null>;
  isLastDayOfGroup: FormControl<boolean | null>;
}

export enum Provider {
  Twilio = 'twilio',
  Telnyx = "telnyx"
}
