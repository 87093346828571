import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenusRoutingModule } from './menus-routing.module';
import { MenusComponent } from './menus.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { IconsModule } from '@progress/kendo-angular-icons';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { VoicemailComponent } from './components/voicemail/voicemail.component';
import { SMSComponent } from './components/sms/sms.component';
import { QueueComponent } from './components/queue/queue.component';
import { RepeatComponent } from './components/repeat/repeat.component';
import { ForwardComponent } from './components/forward/forward.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { BlobNamePipe } from './pipes/blob-name.pipe';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { UtilsModule } from "@progress/kendo-angular-utils";
import { RgMp3Component } from './components/rg-mp3/rg-mp3.component';
import { MenuComponent } from './components/menu/menu.component';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { DefaultComponent } from './components/default/default.component';
import { AgentsComponent } from './components/queue/agents/agents.component';
import { LinkComponent } from './components/link/link.component';
import { OnHoldComponent } from './components/queue/on-hold/on-hold.component';
import { SharedModule } from '../shared/shared.module';
import { MiniPlayerComponent } from '../core/components/mini-player/mini-player.component';
import { PhonePipe } from '../core/pipes/phone.pipe';

@NgModule({ declarations: [
        MenusComponent,
        VoicemailComponent,
        SMSComponent,
        QueueComponent,
        RepeatComponent,
        ForwardComponent,
        PromptComponent,
        BlobNamePipe,
        RgMp3Component,
        MenuComponent,
        DefaultComponent,
        AgentsComponent,
        OnHoldComponent,
        LinkComponent,
        MiniPlayerComponent
    ], imports: [CommonModule,
        MenusRoutingModule,
        SharedModule,
        FormsModule,
        GridModule,
        ButtonsModule,
        InputsModule,
        SortableModule,
        DropDownsModule,
        LabelModule,
        IconsModule,
        ReactiveFormsModule,
        UploadsModule,
        LayoutModule,
        NotificationModule,
        IndicatorsModule,
        PopupModule,
        DialogsModule,
        ListViewModule,
        UtilsModule,
        ScrollViewModule,
        TextBoxModule,
        FloatingLabelModule,
        PhonePipe], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class MenusModule { }
