<kendo-tabstrip class="r-tabs mb-4 mt-4 col-md-12 shadow-sm" (tabSelect)="tabSelect($event)">
  <kendo-tabstrip-tab title="SMS" [selected]="form.controls.tabIndex?.value === 0">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-smile me-1 icon" aria-hidden="true"></em>SMS
    </ng-template>
    <ng-template kendoTabContent>
      <form class="k-form relaygrid-form row p-8 pt-2 pb-0 mms-upload" [formGroup]="form">
        <kendo-formfield class="col-md-6">
          <kendo-floatinglabel text="SMS Text">
            <kendo-textarea #comments name="sms" formControlName="sms" #sms [attr.maxlength]="1600">
              <kendo-textarea-suffix>
                <div class="flex-grow-1">
                  @if (charactersCount > maxlength) {
                    <span class="text-dark fs-9">
                      <em
                        >*<span class="text-uppercase">{{ form.controls.type?.value }}</span> over
                        {{ maxlength }} characters will split & may incur extra charges</em
                        >
                      </span>
                    }
                  </div>
                  <div>
                    <span class="counter fs-9">{{ counter }}</span>
                  </div>
                </kendo-textarea-suffix>
              </kendo-textarea>
            </kendo-floatinglabel>
            <kendo-formerror>SMS is required</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield class="k-form-fieldset col-md-6">
            <kendo-floatinglabel text="SMS From Number (read-only)" class="form-group icon">
              <kendo-textbox formControlName="smsFromNumber" [clearButton]="true" #smsFromNumber [readonly]="true">
                <ng-template kendoTextBoxPrefixTemplate>
                  <em
                    #smsFrompop
                    (click)="help.emit({ anchor: smsFrompop, key: 'smsFrom' })"
                    class="ms-3 fs-4 fa-duotone fa-phone"
                    help="true"
                  ></em>
                </ng-template>
              </kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror>SMS From Number is required</kendo-formerror>
          </kendo-formfield>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
    @if (form.controls.type?.value === 'mms') {
      <kendo-tabstrip-tab
        title="Images"
        [selected]="form.controls.tabIndex?.value === 1"
        >
        <ng-template kendoTabTitle>
          <em class="fa-duotone fa-image me-1 icon" aria-hidden="true"></em>Images ({{ images.length }})
        </ng-template>
        <ng-template kendoTabContent>
          <form class="k-form relaygrid-form row mt-2 mms-upload" [formGroup]="form">
            <div class="col-12">
              <div class="k-form-buttons mb-1 mt-0 align-items-start">
                @if (!showImageUploader) {
                  <button
                    kendoButton
                    themeColor="primary"
                    class="btn btn-light-primary btn-icon icon-35"
                    (click)="showImageUploader = true"
                    title="Add Image"
                    [disabled]="images.length >= 5"
                    >
                    <em class="fa-duotone fa-image"></em>
                  </button>
                }
                @if (showImageUploader) {
                  <button
                    kendoButton
                    themeColor="info"
                    class="btn btn-light-info btn-icon icon-35"
                    (click)="showImageUploader = false"
                    title="Cancel"
                    >
                    <em class="fa-duotone fa-ban"></em>
                  </button>
                }
                @if (showImageUploader) {
                  <kendo-formfield
                    class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload upload-image m-0"
                    style="flex: 1"
                    >
                    @if (showImageUploader) {
                      <kendo-fileselect
                        class="form-group custom-icon help-icon"
                        [multiple]="false"
                        [restrictions]="imageRestrictions"
                        style="width: 100%"
                        #fileSelect
                        formControlName="sayMp3File"
                        (select)="onFileChange($event)"
                        >
                      </kendo-fileselect>
                    }
                    <em class="field-icon fs-4 fa-duotone fa-image w-40px d-done"></em>
                  </kendo-formfield>
                }
                @if (!showImageUploader) {
                  <button
                    kendoButton
                    class="btn btn-light-danger ms-3 btn-icon icon-35"
                    (click)="onDeleteImage()"
                    title="Remove Image"
                    >
                    <em class="fa-duotone fa-image-slash"></em>
                  </button>
                }
              </div>
              @if (images.length) {
                <div class="my-3 image-scrollview">
                  <kendo-scrollview
                    kendoCardMedia
                    [data]="images"
                    width="100%"
                    height="300px"
                    [arrows]="true"
                    [pageable]="false"
                    [pagerOverlay]="'dark'"
                    [activeIndex]="imageIndex"
                    [endless]="true"
                    (itemChanged)="imageIndex = $event.index">
                    <ng-template let-item="item" let-index="index">
                      <div class="image-container">
                        <div class="image-title">
                          {{ item.name }}
                        </div>
                        @if (isString(item.url)) {
                          <img src="{{ item.url }}" class="centered-image"/>
                        }
                      </div>
                    </ng-template>
                  </kendo-scrollview>
                </div>
              }
            </div>
          </form>
        </ng-template>
      </kendo-tabstrip-tab>
    }

    <kendo-tabstrip-tab title=" ">
      <ng-template kendoTabTitle>
        <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
      </ng-template>
      <ng-template kendoTabContent>
        <div class="d-flex justify-content-center m-4 mb-6">
          <div class="d-flex flex-column">
            @if (form.controls.type?.value === 'sms') {
              <p>
                Our <em>SMS</em> feature is a game-changer for your restaurant's communication strategy. When a customer
                calls, they can press a specific digit on their phone to automatically receive key information via text
                message 💬. Whether they need the online ordering link to skip the line, directions 🗺️ to your location, or
                details about your business hours, this feature has got you covered. It's an instant, automated way to share
                essential details and enhance your customers' dining experience.
              </p>
            }
            @if (form.controls.type?.value === 'mms') {
              <p>
                Our <em>MMS</em> 💬 feature is an innovative tool that enhances your restaurant's communication strategy. In
                addition to providing key information such as online ordering links, location, or operating hours, our MMS
                feature enriches customer engagement by sending vivid images 🍝 of your mouth-watering 🤤 meals, daily
                specials, or your full menu directly to their phones. It's a dynamic, automated solution that brings your
                restaurant into the palms of your customers, offering a unique, multi-sensory dining experience.
              </p>
            }
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  @if (showButtons) {
    <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
      <button kendoButton themeColor="primary" class="btn btn-light-primary btn-icon icon-35" (click)="onSave()">
        <em class="fa-duotone fa-floppy-disks"></em>
        <span class="ms-2 label14">Save</span>
      </button>
      <button kendoButton class="btn btn-light-danger btn-icon icon-35" (click)="onDelete()">
        <em class="fa-duotone fa-trash-can"></em>
        <span class="ms-2 label15">Delete</span>
      </button>
    </div>
  }
