import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public context: {title: string, text: string, image: string | null} = {
    title: 'Help Title',
    text: 'Help Text',
    image: 'assets/media/menus-screen/help.gif',
  }

  constructor() { }

  ngOnInit(): void { }
}
