<kendo-tabstrip class="r-tabs mb-4 mt-4 col-md-12 shadow-sm">
  <kendo-tabstrip-tab title="SMS" [selected]="true">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-smile me-1 icon" aria-hidden="true"></em>Preferences
    </ng-template>
    <ng-template kendoTabContent>
      <form class="k-form relaygrid-form row p-8 pt-2 pb-2" [formGroup]="form">
        <kendo-formfield class="k-form-fieldset col-md-6">
          <kendo-floatinglabel text="Forward Number" class="form-group icon">
            <kendo-textbox formControlName="forwardNumber" [clearButton]="true" #forwardNumber>
              <ng-template kendoTextBoxPrefixTemplate>
                <em
                  #forwadtNo
                  (click)="help.emit({ anchor: forwadtNo, key: 'forward' })"
                  class="ms-3 fs-4 fa-duotone fa-forward"
                  help="true"
                ></em>
              </ng-template>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Forward Number is required</kendo-formerror>
        </kendo-formfield>
      </form>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title=" ">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-6">
        <div class="d-flex flex-column">
          <p>
            Our <em>Forward</em> feature adds a new level of flexibility to your restaurant's call handling. By simply
            pressing a specific digit during the IVR call, your customers can be instantly connected to or forwarded to
            another number. Whether it's for catering inquiries, reservations, or direct contact with the chef, this
            feature ensures smooth, effortless communication. It's like having a virtual switchboard operator,
            optimizing your call flow and enhancing customer service.
          </p>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
<div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
  <button kendoButton themeColor="primary" class="btn btn-light-primary btn-icon icon-35" (click)="onSave()">
    <em class="fa-duotone fa-floppy-disks"></em>
    <span class="ms-2 label14">Save</span>
  </button>
  <button kendoButton class="btn btn-light-danger btn-icon icon-35" (click)="onDelete()">
    <em class="fa-duotone fa-trash-can"></em>
    <span class="ms-2 label14">Delete</span>
  </button>
</div>
