@if (showLoader) {
  <div class="loader">
    <kendo-loader [type]="'converging-spinner'" [themeColor]="'info'" [size]="'medium'"> </kendo-loader>
  </div>
}

<div class="body-space menus content">
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <app-menu
        [menuForms]="menuForms"
        [(menuForm)]="menuForm"
        [mp3FilesRestriction]="settings.fileRestrictions!.mp3Files"
        (menuChange)="menuChange($event)"
        (save)="saveMenu()"
        (deleteFile)="deleteSayMp3($event)"
        (changeFile)="addSayMp3($event)"
        (deleteMenu)="deleteMenu()"
        (addMenu)="addMenu()"
        (help)="help.togglePopup($event.anchor, $event.key)"
        (toggleEdit)="menuToggleEdit($event)"
        >
      </app-menu>
      <br />
      <kendo-tabstrip class="r-tabs mb-4 pb-0 shadow-sm custom-tabs">
        <kendo-tabstrip-tab [selected]="true" class="shadow-sm">
          <ng-template kendoTabTitle>
            Digits&nbsp;&nbsp;
            <em class="fa-duotone fa-1 fs-8"></em>
            &nbsp;-&nbsp;<em class="fa-duotone fa-9 fs-8"> </em>&nbsp;
          </ng-template>
          <ng-template kendoTabContent class="shadow-sm">
            <div class="card my-9 mt-0">
              <div class="card-header">
                <h3 class="card-title align-items-start flex-column fw-bolder text-dark">
                  <span class="mt-1 fw-light ng-tns-c556-1 text-gray-400 fs-7">
                    <br /><em>Drag</em> &#9769; menu options (<em>below</em>) to easily change the order of digits.
                  </span>
                </h3>
                <div class="d-flex">
                  <button
                    kendoButton
                    [disabled]="!menuForm.controls._id?.value"
                    class="btn btn-light-primary ms-3 btn-icon icon-35"
                    (click)="addOption()"
                    title="Add option"
                    >
                    <em class="fa-duotone fa-square-plus"></em>
                    <span class="ms-2 label6">Add</span>
                  </button>
                  <button
                    kendoButton
                    [disabled]="!options.length || optionsChecked.length === 0"
                    class="btn btn-light-danger ms-2 btn-icon icon-35"
                    (click)="deleteOptions()"
                    title="Delete options"
                    >
                    <em class="fa-duotone fa-trash-can"></em>
                    <span class="ms-2 label6">Delete</span>
                  </button>
                </div>
              </div>
              <div class="card-body pt-3 p-0 table-responsive">
                <!--begin::Table-->
                <table
                  class="table table-no-border menu-option-table re-custom-table custom-collapse calls-detail-table custom-toogle m-0"
                  >
                  <!--begin::Table head-->
                  <thead class="m-0 px-2 mo-table-head">
                    <tr class="mo-table-tr py-3">
                      <th class="check mo-table-th"><input type="checkbox" kendoCheckBox (click)="checkAll()" /></th>
                      <th class="digit mo-table-th">Digit #</th>
                      <th class="logo mo-table-th">Type</th>
                      <th class="say mo-table-th">Prompt</th>
                      <th class="action mo-table-th ps-8">Action</th>
                    </tr>
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  @if (options.length === 0) {
                    <tbody>
                      <tr>
                        <td colspan="5" class="text-center">
                          <div class="p-5">
                            <p class="text-danger text-center">
                              <i class="fa-duotone fa-brake-warning"></i> No menu options have been configured for this
                              IVR Menu. Caller will only hear <i class="fa-duotone fa-ear-listen"></i>
                              the greeting from this IVR Menu. Please click the Add button to start adding menu options.
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  }
                  @if (options.length > 0) {
                    <tbody class="mo-table-body">
                      @for (optionForm of options; track optionForm; let i = $index) {
                        <div
                          class="menusOptionForm"
                          [formGroup]="optionForm"
                          [ngClass]="{ 'row-view-only': !optionForm.controls.sStatus?.value }"
                          style="width: 100%"
                          >
                          <!-- <tr>
                          <td colspan="10">
                            {{ optionForm.controls.sayMp3?.value! | json }}
                          </td>
                        </tr> -->
                        <tr [title]="options[i].controls.type?.value" class="menuTypeRow mo-table-tr p-0">
                          <td class="check mo-table-td">
                            <input type="checkbox" kendoCheckBox formControlName="delete" />
                          </td>
                          <td class="digit mo-table-td">
                            <div class="symbol symbol-45px">
                              <span class="symbol-label fw-bolder bg-light-info">
                                {{ optionForm.controls.digits?.value }}
                                <span class="upDownButton d-flex flex-column flex-nowrap ms-3">
                                  <button
                                    kendoButton
                                    (click)="resequence(-1, i)"
                                    class="btn text-dark btn-icon icon-20 bg-none border-0 p-0 fs-4"
                                    title="Move up &uarr;"
                                    >
                                    <em class="icon fa-duotone fa-caret-up"></em>
                                  </button>
                                  <button
                                    kendoButton
                                    (click)="resequence(1, i)"
                                    class="btn text-dark btn-icon icon-20 bg-none border-0 p-0 fs-4"
                                    title="Move down &darr;"
                                    >
                                    <em class="icon fa-duotone fa-caret-down"></em>
                                  </button>
                                </span>
                              </span>
                            </div>
                          </td>
                          <td class="logo mo-table-td">
                            <div class="types nav nav-pills nav-pills-custom menusTypePopup">
                              <kendo-dropdownlist
                                class="menuItemSelector"
                                [data]="optionTypes"
                                textField="text"
                                valueField="value"
                                formControlName="type"
                                [valuePrimitive]="true"
                                (valueChange)="optionTypeChange($event, i)"
                                [popupSettings]="{ width: 180 }"
                                >
                                <ng-template class="menuItemValue" kendoDropDownListValueTemplate let-dataItem>
                                  <div
                                    class="nav-link d-flex flex-column flex-center overflow-hidden w-55px h-55px p-2"
                                    >
                                    <div class="nav-icon">
                                      <img class="w-35px" src="{{ dataItem?.img }}" alt="" />
                                    </div>
                                    <span class="nav-text text-gray-700 fw-bolder lh-1 text-center">{{
                                      dataItem?.text
                                    }}</span>
                                  </div>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                  <p class="template menuItems d-flex flex-row flex-nowrap align-items-center m-0">
                                    <img
                                      class="me-3 selectimg"
                                      style="width: 22px"
                                      src="{{ dataItem.img }}"
                                      alt="Icon"
                                      />
                                      {{ dataItem.text }}
                                    </p>
                                  </ng-template>
                                </kendo-dropdownlist>
                              </div>
                            </td>
                            <td class="say mo-table-td">
                              <div class="text-input-row d-flex flex-row k-form m-0">
                                <div class="k-form relaygrid-form row">
                                  <div #sayText class="px-0 toggle-fieldset say-fieldset m-0">
                                    @if (optionForm.controls.type?.value === option.link) {
                                      <kendo-floatinglabel
                                        text="Menus"
                                        class="form-group icon m-0"
                                        >
                                        <kendo-dropdownlist
                                          [data]="menuForms.value"
                                          formControlName="menu"
                                          textField="name"
                                          valueField="_id"
                                          [valuePrimitive]="true"
                                          [disabled]="!optionForm.controls.sStatus?.value!"
                                          >
                                          <ng-template kendoDropDownListValueTemplate let-dataItem>
                                            <em class="me-2 mt-1 fs-4 fa-duotone fa-bars"></em>&nbsp;{{ dataItem?.name }}
                                          </ng-template>
                                        </kendo-dropdownlist>
                                      </kendo-floatinglabel>
                                    }
                                    @if (
                                      !optionForm.controls.isSayMp3?.value &&
                                      optionForm.controls.type?.value !== option.link
                                      ) {
                                      <kendo-formfield
                                        >
                                        <kendo-floatinglabel class="form-group icon m-0">
                                          <kendo-textbox formControlName="say" #say spellcheck="false">
                                            <ng-template kendoTextBoxPrefixTemplate>
                                              @if (optionForm.value.say) {
                                                <rgrid-mini-player
                                                  [_id]="optionForm.value._id!"
                                                  class="ms-2"
                                                  style="width: 75px"
                                                ></rgrid-mini-player>
                                              }
                                            </ng-template>
                                          </kendo-textbox>
                                        </kendo-floatinglabel>
                                        <kendo-formerror>Say is required</kendo-formerror>
                                      </kendo-formfield>
                                    }
                                    @if (optionForm.get('isSayMp3')?.value) {
                                      @if (!optionForm.get('sayMp3')?.value?.url) {
                                        <kendo-formfield
                                          class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload"
                                          style="width: 100%"
                                          >
                                          <kendo-floatinglabel>
                                            <kendo-fileselect
                                              class="form-group custom-icon help-icon m-0"
                                              formControlName="sayMp3File"
                                              #sayMp3
                                              [multiple]="false"
                                          (select)="
                                            addSayMp3({ selectEvent: $event, fileForm: optionForm.controls.sayMp3! }, i)
                                          "
                                              [restrictions]="settings.fileRestrictions!.mp3Files"
                                              >
                                            </kendo-fileselect>
                                          </kendo-floatinglabel>
                                        </kendo-formfield>
                                      }
                                      @if (optionForm.controls.sayMp3?.value?.name) {
                                        <kendo-formfield>
                                          <kendo-floatinglabel
                                            class="form-group icon m-0"
                                            [formGroup]="optionForm.controls.sayMp3!"
                                            >
                                            <kendo-textbox
                                              formControlName="name"
                                              #say
                                              spellcheck="false"
                                              [readonly]="true"
                                              >
                                              <ng-template kendoTextBoxPrefixTemplate>
                                                <rgrid-mini-player
                                                  [file]="(optionForm.controls.sayMp3?.controls?.url)!.value!"
                                                  class="ms-2"
                                                  style="width: 75px"
                                                ></rgrid-mini-player>
                                              </ng-template>
                                            </kendo-textbox>
                                          </kendo-floatinglabel>
                                          <kendo-formerror>Say is required</kendo-formerror>
                                        </kendo-formfield>
                                      }
                                      @if (
                                        optionForm.controls.sStatus?.value && optionForm.controls.sayMp3?.value?.url
                                        ) {
                                        <button
                                          (click)="deleteSayMp3(optionForm.controls.sayMp3!)"
                                          class="border-0 bg-transparent ms-4"
                                          >
                                          <span class="fa-duotone fa-trash-can fa-xl"></span>
                                        </button>
                                      }
                                    }
                                    <kendo-formfield class="k-form-fieldset toggle-fieldset-btn m-0 mt-6 p-0">
                                      <kendo-label class="form-title" [for]="isSayMp3" text="Is Say Mp3?"></kendo-label>
                                      <kendo-switch formControlName="isSayMp3" #isSayMp3 onLabel="WAV" offLabel="Text">
                                      </kendo-switch>
                                    </kendo-formfield>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="action ps-8 mo-table-td">
                              @if (!optionForm.controls.sStatus?.value) {
                                <button
                                  class="btn btn-icon icon-35 btn-light-info"
                                  type="button"
                                  (click)="expandOption(optionForm)"
                                  >
                                  <i class="fa-duotone fa-pen-to-square fs-3"></i>&nbsp;&nbsp;
                                  <span class="label7">Edit</span>
                                </button>
                              }
                              @if (optionForm.controls.sStatus?.value) {
                                <button
                                  (click)="collapseOption(optionForm, i)"
                                  class="btn btn-light-info btn-icon icon-35"
                                  type="button"
                                  >
                                  <i class="fa-duotone fa-ban"></i>&nbsp;&nbsp;
                                  <span class="label7">Cancel</span>
                                </button>
                              }
                            </td>
                          </tr>
                          <tr
                            class="mo-table-tr"
                            [@animationShowHide]="optionForm.controls.sStatus?.value"
                            class="togglable-row p-0 m-0 mt-2"
                            [title]="optionForm.controls.type?.value"
                            >
                            <td colspan="5" class="item-collapsable-table mo-table-td" style="width: 100%">
                              @if (
                                optionForm.controls.type?.value === option.sms ||
                                optionForm.controls.type?.value === option.mms
                                ) {
                                <app-sms
                                  (help)="help.togglePopup($event.anchor, $event.key)"
                                  (save)="saveOption($event)"
                                  (delete)="deleteOption($event)"
                                  [imageRestrictions]="settings.fileRestrictions!.images"
                                  [formGroup]="optionForm"
                                  [index]="i"
                                  (addImage)="addImage($event)"
                                  (deleteImage)="deleteImage($event)"
                                  >
                                </app-sms>
                              }
                              @if (optionForm.controls.type?.value === option.vmail) {
                                <app-voicemail
                                  (help)="help.togglePopup($event.anchor, $event.key)"
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  (changeFile)="addSayMp3($event)"
                                  (deleteFile)="deleteSayMp3($event)"
                                  >
                                </app-voicemail>
                              }
                              @if (optionForm.controls.type?.value === option.queue) {
                                <app-queue
                                  (help)="help.togglePopup($event.anchor, $event.key)"
                                  [formGroup]="optionForm"
                                  [mp3FilesRestriction]="settings.fileRestrictions!.mp3Files"
                                  (delete)="deleteOption($event)"
                                  (deleteFile)="deleteSayMp3($event)"
                                  (save)="saveOption($event)"
                                  (changeFile)="addSayMp3($event)"
                                  (addImage)="addImage($event)"
                                  (deleteImage)="deleteImage($event)"
                                  >
                                </app-queue>
                              }
                              @if (optionForm.controls.type?.value === option.repeat) {
                                <app-repeat
                                  (help)="help.togglePopup($event.anchor, $event.key)"
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  >
                                </app-repeat>
                              }
                              @if (optionForm.controls.type?.value === option.forward) {
                                <app-forward
                                  (help)="help.togglePopup($event.anchor, $event.key)"
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  >
                                </app-forward>
                              }
                              @if (optionForm.controls.type?.value === option.prompt) {
                                <app-prompt
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  >
                                </app-prompt>
                              }
                              @if (optionForm.controls.type?.value === option.link) {
                                <app-link
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  [menus]="menuForms.value"
                                  >
                                </app-link>
                              }
                              @if (optionForm.controls.type?.value === option.none) {
                                <app-default
                                  [formGroup]="optionForm"
                                  (delete)="deleteOption($event)"
                                  (save)="saveOption($event)"
                                  >
                                </app-default>
                              }
                            </td>
                          </tr>
                        </div>
                      }
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <style>
          .numberColor {
          --fa-secondary-color: #c0c7d3;
        }
      </style>
      <kendo-tabstrip-tab title=" ">
        <ng-template kendoTabTitle>
          <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
        </ng-template>
        <ng-template kendoTabContent>
          <div class="d-flex justify-content-center m-4 mb-6">
            <div class="d-flex flex-column">
              <p>
                This 'Digits' section allows you to configure the menu options for this IVR Menu. You can add as many
                as nine menu options. Each menu option can be configured to perform a different action when the caller
                presses the corresponding digit on their phone keypad. For example, you can configure the system so
                that the caller is forwarded to a different phone number when they press
                <em class="fa-duotone fa-circle-1 numberColor fs-3"></em>, or sent to voicemail when they press
                <em class="fa-duotone fa-circle-2 numberColor fs-3"></em>, or receive a text message when they press
                <em class="fa-duotone fa-circle-3 numberColor fs-3"></em>, etc.
              </p>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
</div>
<br />
<br />
<br />
<div kendoDialogContainer></div>
