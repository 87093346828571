import { GridDataResult } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { delay, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/assets/config/app-settings';

export abstract class GridService extends BehaviorSubject<GridDataResult> {
  public loading!: boolean;

  constructor(public http: HttpClient, protected collectionName: string, public settings: AppSettings) {
    super({
      data: [],
      total: 0,
    });
  }

  public query(state: any, queryStr: string): void {
    this.fetch(this.collectionName, state, queryStr)
      .subscribe((x) => {
        super.next(x)
      });
  }

  protected fetch(collectionName: string, state: any, queryStr: string): Observable<GridDataResult> {
    const oData = `${toDataSourceRequestString(state)}`;

    this.loading = true;

    return this.http.get(`${this.settings.apiBaseUrl}/api/${collectionName}?${oData}&${queryStr}`).pipe(
      map(
        (response: any) =>
          <GridDataResult>{
            data: response.data.workers,
            total: parseInt(response.count, 10),
            meta: response.data.meta,
          }
      ),
      delay(500),
      tap(() => (this.loading = false))
    );
  }
}
