import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileRestrictions, FileSelectComponent, SelectEvent } from '@progress/kendo-angular-upload';
import { FileState, Image, OptionForm, FileForm } from '../../models/menu';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
})
export class SMSComponent implements OnInit {
  @ViewChild('fileSelect') fileSelect!: FileSelectComponent;

  @Output() help = new EventEmitter<{ anchor: ElementRef | HTMLElement, key: string }>();
  @Output() save = new EventEmitter<FormGroup<OptionForm>>();
  @Output() delete = new EventEmitter<FormGroup<OptionForm>>();
  @Output() addImage = new EventEmitter<{ selectEvent: SelectEvent, optionForm: FormGroup<OptionForm>, callback?: () => void }>();
  @Output() deleteImage = new EventEmitter<{ optionForm: FormGroup<OptionForm>, fileForm: FormGroup<FileForm> }>();

  public form = new FormGroup<OptionForm>({});
  public images: Image[] = [];
  
  @Input() imageRestrictions!: FileRestrictions;
  @Input() set formGroup(value: FormGroup<OptionForm>) {
    this.form = value;
    this.images = this.form.controls.images?.value.filter(x => x.state !== FileState.deleted) ?? [];
    this.form.controls.images?.valueChanges.subscribe((images: Image[]) => {
      this.images = images.filter(x => x.state !== FileState.deleted);
      this.imageIndex = this.images.length - 1;
    });
    this.charactersCount = this.form.controls.sms?.value ? this.form.controls.sms?.value.length : 0;
    this.counter = `${this.charactersCount}/${this.maxlength}`
    if(this.form.controls.type?.value === 'mms') {
      this.maxlength = 1600 -22;
    }
  }  
  @Input() showButtons = true;

  private _index: number = -1;
  @Input() set index(value: number) {
    this._index = value;
  }

  public skip = 0;
  public pageSize = 1;
  public total = 0;
  public imageIndex = 0;
  public showSMS = false;
  public showSMSFrom = false;
  public showLoader = false;
  public showImageUploader = false;
  public maxlength = 138;
  public charactersCount: number = 0;
  public counter: string = '';

  ngOnInit(): void {
    this.form.controls.sms?.valueChanges.subscribe((value) => {
      if(!value) {
        this.charactersCount = 0;
      } else {
        this.charactersCount = value.length;
      };      
      this.counter = `${this.charactersCount}/${this.maxlength}`;
    });
  }

  onSave() {
    this.save.emit(this.form);
  }

  onDelete() {
    this.delete.emit(this.form);
  }

  onFileChange($event: SelectEvent) {
    this.addImage.emit({
      selectEvent: $event,
      optionForm: this.form,
      callback: () => {
        this.fileSelect.clearFiles();
        this.showImageUploader = false;
        this.imageIndex = this.form.controls.images!.length - 1
      }
    });
  }

  public onDeleteImage() {
    this.deleteImage.emit({ optionForm: this.form, fileForm: this.form.controls.images?.controls[this.imageIndex]! });
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  public tabSelect(e: any): void {
    this.form.controls.tabIndex?.patchValue(e.index);
  }
}
