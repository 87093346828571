<kendo-tabstrip class="r-tabs mb-4 mt-4 col-md-12 shadow-sm">
  <kendo-tabstrip-tab title="SMS"
    [selected]="true">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-question icon fs-4"
        aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-8">
        <div class="d-flex flex-column">
          <p>The <em>Prompt</em> feature on a restaurant IVR system enables callers to access important information
            through recorded messages. For example, the feature could highlight limited-time promotions, encouraging
            customers to place orders for high-profit dishes, boosting the restaurant's revenue.</p>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
  <button kendoButton
    themeColor="primary"
    class="btn btn-light-primary btn-icon icon-35"
    (click)="onSave()"
    title="Save">
    <em class="fa-duotone fa-floppy-disks"></em>
    <span class="ms-2 label7">Save</span>
  </button>
  <button kendoButton
    class="btn btn-light-danger btn-icon icon-35"
    (click)="onDelete()"
    title="Delete">
    <em class="fa-duotone fa-trash-can"></em>
    <span class="ms-2 label7">Delete</span>
  </button>
</div>