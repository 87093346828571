@if (
  (form.get('workspaceId')?.value && form.get('workflowSid')?.value && form.get('taskQueueSid')?.value) ||
  form.get('friendlyName')?.value
  ) {
  <kendo-tabstrip
    class="r-tabs mt-5 mb-4 pb-0 shadow-sm"
    (tabSelect)="tabSelect($event)"
    >
    <kendo-tabstrip-tab title="Agents" [selected]="form.controls.tabIndex?.value === 0">
      <ng-template kendoTabTitle> <i class="fa-duotone fa-headset me-1 icon" aria-hidden="true"></i>Agents </ng-template>
      <ng-template kendoTabContent>
        <queue-agents [optionForm]="form" [queueSaveClicked]="queueSaveClicked$"> </queue-agents>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Wait" [selected]="form.controls.tabIndex?.value === 1">
      <ng-template kendoTabTitle>
        <i class="fa-duotone fa-music me-1 icon" aria-hidden="true"></i>On Hold Music
      </ng-template>
      <ng-template kendoTabContent>
        <queue-on-hold
          [optionForm]="form"
          (deleteMp3)="deleteFile.emit($event)"
          (changeFile)="changeFile.emit($event)"
          [mp3FilesRestriction]="mp3FilesRestriction"
          >
        </queue-on-hold>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Queue" [selected]="form.controls.tabIndex?.value === 2">
      <ng-template kendoTabTitle> <em class="fa-duotone fa-info me-1 icon" aria-hidden="true"></em>Info </ng-template>
      <ng-template kendoTabContent>
        <form class="k-form relaygrid-form row p-6" [formGroup]="form">
          <kendo-formfield class="k-form-fieldset col-md-6 px-3">
            <kendo-floatinglabel text="Queue Name" class="form-group icon">
              <kendo-textbox formControlName="friendlyName" [readonly]="true">
                <ng-template kendoTextBoxPrefixTemplate>
                  <em class="ms-3 fs-4 fa-duotone fa-face-sunglasses"></em>
                </ng-template>
              </kendo-textbox>
            </kendo-floatinglabel>
          </kendo-formfield>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title=" " [selected]="form.controls.tabIndex?.value === 3">
      <ng-template kendoTabTitle>
        <em class="fa-duotone fa-phone-missed me-1 icon" aria-hidden="true"></em>Missed Call Diversion
      </ng-template>
      <ng-template kendoTabContent>
        <div [formGroup]="form" class="p-4">
          <label class="form-check form-check-inline">
            <input
              class="form-check-input mt-1"
              type="radio"
              name="missedCallEnabled"
              [value]="true"
              #missedCallEnabled
              formControlName="missedCallEnabled"
              kendoRadioButton
              />
              Send a Text
            </label>
            <label class="form-check form-check-inline ms-1">
              <input
                class="form-check-input mt-1"
                type="radio"
                name="missedCallEnabled"
                [value]="false"
                #missedCallEnabled
                formControlName="missedCallEnabled"
                kendoRadioButton
                />
                Leave a Voicemail
              </label>
            </div>
            @if (mmsForm && form.controls.missedCallEnabled!.value) {
              <app-sms
                [imageRestrictions]="settings.fileRestrictions!.images"
                [formGroup]="mmsForm"
                [index]="0"
                (addImage)="addImage.emit($event)"
                (deleteImage)="deleteImage.emit($event)"
                [showButtons]="false"
                >
              </app-sms>
            }
            @if (!form.controls.missedCallEnabled!.value) {
              <form
                class="k-form relaygrid-form row p-6 pt-0 pb-0 mt-4"
                [formGroup]="form"
                >
                <kendo-formfield class="k-form-fieldset col-md-6 px-3">
                  <kendo-floatinglabel text="Voicemail To #" class="form-group icon">
                    <kendo-textbox formControlName="vmailToSms" [clearButton]="true" #workspaceId>
                      <ng-template kendoTextBoxPrefixTemplate>
                        <em
                          #vmailto
                          (click)="help.emit({ anchor: vmailto, key: 'vmail-to' })"
                          class="ms-3 fs-4 fa-duotone fa-mobile-screen-button"
                          help="true"
                        ></em>
                      </ng-template>
                    </kendo-textbox>
                  </kendo-floatinglabel>
                  <kendo-formerror>To Number is required, for forwarding voicemail notifications to</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield class="k-form-fieldset col-md-6 px-3">
                  <kendo-floatinglabel text="Voicemail To Email" class="form-group icon">
                    <kendo-textbox formControlName="vmailToEmail" [clearButton]="true">
                      <ng-template kendoTextBoxPrefixTemplate>
                        <em
                          #tqId
                          (click)="help.emit({ anchor: tqId, key: 'vmail-to-email' })"
                          help="true"
                          class="ms-3 fs-4 fa-duotone fa-envelope-circle-check"
                        ></em>
                      </ng-template>
                    </kendo-textbox>
                  </kendo-floatinglabel>
                  <kendo-formerror>To email is optional, for forwarding voicemail notifications to</kendo-formerror>
                </kendo-formfield>
                <div
                  #greetingText
                  [formGroup]="form"
                  class="col-md-12 toggle-fieldset say-fieldset align-self-start k-form relaygrid-form mb-2"
                  >
                  @if (!form.get('vmailIsSayMp3')?.value) {
                    <kendo-formfield class="k-form-fieldset text-fieldset mb-0 pb-0">
                      <kendo-floatinglabel text="Voicemail Prompt" class="form-group">
                        <kendo-textarea formControlName="vmailSay" [rows]="4" #sayBye class="k-focus">
                          <kendo-textarea-suffix>
                            <em
                              (click)="help.emit({ anchor: greetingText, key: 'say' })"
                              help="true"
                              class="m-3 fs-4 fa-duotone fa-comment-smile"
                            ></em>
                          </kendo-textarea-suffix>
                        </kendo-textarea>
                      </kendo-floatinglabel>
                      <kendo-formerror>Greeting is required</kendo-formerror>
                    </kendo-formfield>
                  }
                  @if (form.get('vmailIsSayMp3')?.value) {
                    @if (!form.controls.vmailSayMp3?.controls?.url?.value) {
                      <kendo-formfield
                        class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload m-0"
                        style="width: 100%"
                        >
                        <kendo-floatinglabel
                          text="Greeting"
                          formGroupName="vmailSayMp3"
                          class="form-group custom-icon label_height iconpos"
                          >
                          <kendo-fileselect
                            class="form-group custom-icon help-icon m-0 mb-2"
                            [multiple]="false"
                            formControlName="file"
                            (select)="onMp3ChangeFile($event)"
                            [restrictions]="mp3FilesRestriction"
                            style="width: 100%"
                            >
                          </kendo-fileselect>
                        </kendo-floatinglabel>
                      </kendo-formfield>
                    }
                    @if (form.controls.vmailSayMp3?.controls?.url?.value!) {
                      <rg-mp3
                        [state]="form.controls.vmailSayMp3?.controls?.state?.value!"
                        [filename]="form.controls.vmailSayMp3?.controls?.name?.value!"
                        [mp3]="form.controls.vmailSayMp3?.controls?.url?.value!"
                        style="width: 100%"
                        class="mb-4"
                        >
                      </rg-mp3>
                      @if (form.get('vmailSayMp3')?.value) {
                        <button
                          kendoButton
                          class="btn-delete file-delete ms-0 mb-4"
                          (click)="onDeleteVmailMp3()"
                          type="button"
                          title="Remove Mp3"
                          >
                          <span class="btn-icon fa-duotone fa-trash-can"></span>
                        </button>
                      }
                    }
                  }
                  &nbsp;
                  <kendo-formfield class="k-form-fieldset toggle-fieldset-btn mt-7 align-self-baseline pl-4">
                    <kendo-switch formControlName="vmailIsSayMp3" #vmailIsSayMp3 onLabel="WAV" offLabel="Text"> </kendo-switch>
                  </kendo-formfield>
                </div>
              </form>
            }
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title=" " [selected]="form.controls.tabIndex?.value === 4">
          <ng-template kendoTabTitle>
            <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
          </ng-template>
          <ng-template kendoTabContent>
            <div class="d-flex justify-content-center m-4 mb-6">
              <div class="d-flex flex-column">
                <p>
                  Our <em>Queue</em> feature is designed to streamline your restaurant's call 📲 handling process. When a
                  customer calls, it triggers a ring 🔔 to all available staff simultaneously. The first to respond takes the
                  call, ensuring quick response times and improved customer service. Think of it as having a virtual host,
                  available 24/7, managing your call traffic 🚥 efficiently.
                </p>
                <p>
                  With our <em class="fa-duotone fa-phone-missed"></em> Missed Call feature, an SMS can be automatically sent
                  out to a caller who's call was missed or not answered while they were waiting in the call queue.
                </p>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    }

    <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
      <button
        kendoButton
        themeColor="primary"
        class="btn btn-light-primary btn-icon icon-35"
        (click)="onSave()"
        title="Save"
        >
        <em class="fa-duotone fa-floppy-disks"></em>
        <span class="ms-2 label7">Save</span>
      </button>
      <button kendoButton class="btn btn-light-danger btn-icon icon-35" (click)="onDelete()" title="Delete">
        <em class="fa-duotone fa-trash-can"></em>
        <span class="ms-2 label7">Delete</span>
      </button>
    </div>
