<kendo-grid
  [data]="gridService.data"
  id="productsGrid"
  (cellClick)="cellClickHandler($event)"
  (add)="addHandler()"
  (remove)="removeHandler($event)"
  class="pt-0"
  >
  <ng-template kendoGridToolbarTemplate>
    <div class="mt-2">
      @if (!formGroup) {
        <button
          kendoGridAddCommand
          class="mb-3 btn btn-light-primary btn-small mt-0"
          title="Add Agent"
          >
          <div class="p-1 me-2">
            <em class="icon me-0 fa-duotone fa-plus"></em>&nbsp;
            <em class="con me-0 fa-duotone fa-user-headset"></em>
            Add Agent
          </div>
        </button>
      }
      @if (formGroup) {
        <div>
          <button
            kendoButton
            class="mb-3 btn btn-light-primary btn-small"
            [disabled]="!formGroup.valid"
            (click)="saveRow()"
            >
            <div class="p-1 me-2">
              <em class="icon me-0 fa-duotone fa-plus"></em>&nbsp;
              <em class="con me-0 fa-duotone fa-save"></em>
              Accept Changes
            </div>
          </button>
          <button kendoButton class="mb-3 btn btn-light-danger btn-small" (click)="cancelHandler()">
            <div class="p-1 me-2">
              <em class="cancel con me-0 fa-duotone fa-save"></em>
              Cancel
            </div>
          </button>
        </div>
      }
    </div>
  </ng-template>
  <kendo-grid-column
    field="originalInterface"
    [hidden]="true"
    [editable]="false"
    editor="text"
    title="Id"
  ></kendo-grid-column>
  <kendo-grid-column field="queue_name" [hidden]="true" [editable]="false" title="Queue"></kendo-grid-column>
  <kendo-grid-column field="interface" title="Phone">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex"
      >&nbsp; <em class="icon fa-duotone fa-phone-volume me-1"></em> {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.interface | phone }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-formfield>
        <kendo-maskedtextbox
          #myMaskedTextbox
          appCursorStart
          [style.width.px]="170"
          [mask]="'(000) 000-0000'"
          [formControl]="formGroup.controls.interface"
          >
        </kendo-maskedtextbox>
        <kendo-formhint align="start">Please enter a phone number for this agent.</kendo-formhint>
        <kendo-formerror>Phone Number is required.</kendo-formerror>
      </kendo-formfield>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column editor="text" field="membername" title="Name">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex"
      >&nbsp; <em class="icon fa-duotone fa-user-headset me-1"></em> {{ column.title }}
    </ng-template>
    <ng-template c let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-formfield>
        <kendo-textbox [formControl]="formGroup.controls.membername"></kendo-textbox>
        <kendo-formhint align="start">Please enter a name for this agent.</kendo-formhint>
        <kendo-formerror>Agent name is required.</kendo-formerror>
      </kendo-formfield>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="available" editor="boolean" title="Available">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex"
      >&nbsp; <em class="icon fa-duotone fa-bell-on me-1"></em> {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <input
        kendoCheckBox
        type="checkbox"
        [maxLength]="10"
        [(ngModel)]="dataItem.available"
        title="Available"
        />
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        @if (formGroup?.controls.available) {
          <input
            kendoCheckBox
            type="checkbox"
            [maxLength]="10"
            [formControl]="formGroup?.controls.available"
            title="Available"
            />
        }
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" [width]="220">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand class="me-3 mb-3 btn btn-light-danger btn-small" title="Remove Agent">
          <div class="p-1">
            <em class="icon me-0 fa-duotone fa-minus"></em>&nbsp;
            <em class="icon me-0 fa-duotone fa-trash-can"></em>
            <span class="label">Remove</span>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
