import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { AgentsService } from 'src/app/core/services/agents.service';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { ActivityService } from 'src/app/menus/components/queue/services/activity.service';
import { FileForm, FileState, OptionForm } from '../../models/menu';
import { Provider } from 'src/app/core/models/customer.model';
import { ChangeFileEvent } from '../../models/events';
import { AppSettings } from 'src/assets/config/app-settings';
import { createOptionForm } from '../../services/menu-form.service';
import { Option } from 'src/app/menus/models/menu';
import { Options } from 'src/app/core/enums/options';
import { BaseComponent } from 'src/app/core/common/base.component';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
  providers: [AgentsService],
})
export class QueueComponent extends BaseComponent {
  public form = new FormGroup<OptionForm>({});
  public mmsForm: FormGroup<OptionForm> | undefined;

  @Input() mp3FilesRestriction!: FileRestrictions;
  @Input() set formGroup(value: FormGroup<OptionForm>) {
    this.form = value ?? new FormGroup<OptionForm>({});

    const mmsOption: Option = {
      _id: this.form.controls._id?.value,
      type: Options.mms,
      sms: this.form.controls.missedCallSms?.value,
      images: this.form.controls.images?.value.map((image: any) => image.url),
      smsFromNumber: this.session.user?.selectedNumber?.number,
    };

    this.mmsForm = createOptionForm(mmsOption, false);
    this.mmsForm.controls.images?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      const imagesArray = this.form.controls.images as FormArray<FormGroup<FileForm>>;
      const mmsImages = this.mmsForm?.controls.images?.value;

      imagesArray.clear();

      if (mmsImages && Array.isArray(mmsImages)) {
        mmsImages.forEach((image) => {
          const imageGroup = new FormGroup<FileForm>({
            file: new FormControl<string | null>(image.file!),
            state: new FormControl<FileState | null>(image.state!),
            url: new FormControl<string | null>(image.url!),
            name: new FormControl<string | null>(image.name!),
          });
          imageGroup.patchValue(image);
          imagesArray.push(imageGroup);
        });
      }
    });
  }

  @Output() help = new EventEmitter<{ anchor: ElementRef | HTMLElement | any; key: string }>();
  @Output() save = new EventEmitter<FormGroup<OptionForm>>();
  @Output() delete = new EventEmitter<FormGroup<OptionForm>>();
  @Output() changeFile = new EventEmitter<ChangeFileEvent>();
  @Output() deleteFile = new EventEmitter<FormGroup<FileForm>>();

  @Output() addImage = new EventEmitter<{
    selectEvent: SelectEvent;
    optionForm: FormGroup<OptionForm>;
    callback?: (result: FormGroup<FileForm>) => void;
  }>();
  @Output() deleteImage = new EventEmitter<{ optionForm: FormGroup<OptionForm>; fileForm: FormGroup<FileForm> }>();

  public queueSaveClicked$: Subject<void> = new Subject<void>();
  public providerEnum = Provider;

  constructor(
    protected activityService: ActivityService,
    public session: SessionService,
    public settings: AppSettings
  ) {
    super();
  }

  onSave() {
    this.queueSaveClicked$.next();
    this.form.controls.missedCallSms?.patchValue(this.mmsForm?.controls.sms?.value!);
    this.save.emit(this.form as any);
  }

  onDeleteVmailMp3() {
    this.deleteFile.emit(this.form.controls.vmailSayMp3);
    this.form.controls.sayMp3File?.patchValue(null);
  }

  onMp3ChangeFile(e: SelectEvent) {
    this.changeFile.emit({ selectEvent: e, fileForm: this.form.controls.vmailSayMp3! });
  }

  onDelete() {
    this.delete.emit(this.form as any);
  }

  public tabSelect(e: any): void {
    this.form.controls.tabIndex?.patchValue(e.index);
  }
}


