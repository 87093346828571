import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormGroup, UntypedFormGroup } from '@angular/forms';
import { FileInfo } from '@progress/kendo-angular-upload';
import { OptionForm, RgFormGroup } from '../../models/menu';

@Component({
  selector: 'app-forward',
  templateUrl: './forward.component.html',
  styleUrls: ['./forward.component.scss'],
})
export class ForwardComponent implements OnInit {
  form = new UntypedFormGroup({});
  @Input() set formGroup(value: UntypedFormGroup) {
    this.form = value;
  }

  @Output() help = new EventEmitter<{ anchor: ElementRef | HTMLElement, key: string }>();
  @Output() save = new EventEmitter<FormGroup<OptionForm>>();
  @Output() delete = new EventEmitter<FormGroup<OptionForm>>();

  ngOnInit(): void {}

  onSave() {
    this.save.emit(this.form as any);
  }

  onDelete() {
    this.delete.emit(this.form as any);
  }

  public sayMp3Selected(e: FileInfo[]): void {
  }

  public sayByeMp3Selected(e: FileInfo[]): void {
  }

  public forwadtNoPop = false;
}
