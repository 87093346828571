import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { SessionService } from "src/app/core/services/session.service";
import { AppSettings } from "src/assets/config/app-settings";
import { Agent } from "../models/agent.model";

@Injectable()
export class AgentGridService {
  public data: Agent[] = [];
  private url: string;
  public orginalData: Agent[] = [];

  public products(): unknown[] {
    return this.data;
  }

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    public session: SessionService) {
    this.url = `${this.settings.teleApiBaseUrl}/api/v1/queue_members/`;
  }

  private headers = {
    Authorization: `Token ${this.settings.teleApiKey}`,
    'Content-Type': 'application/json'
  };

  public remove(dataItem: Agent) {
    const index = this.data.findIndex(({ originalInterface }) => originalInterface === dataItem.originalInterface);
    this.data.splice(index, 1);
    return this.data;
  }

  public save(newAgent: Agent, isNew: boolean): void {
    if (isNew) {
      this.data.splice(0, 0, newAgent);
    } else {
      const oldAgent = this.data.find(({ originalInterface }) => originalInterface === newAgent.originalInterface)!;
      Object.assign(
        oldAgent,
        newAgent
      );
    }
  }

  public fetch(queue: string = ''): Observable<any[]> {
    const url = `${this.url}?&queue_name=${queue}`;
    return this.http
      .get(url, { headers: this.headers })
      .pipe(
        map((data: any) => {
          data.members = data.members.map(mapAgent());
          this.orginalData = [...data.members];
          this.data = data.members;
          return this.data;
        })
      );
  }

  public delete(queue: string): Observable<any> {
    if(this.orginalData.length === 0) 
      return of(null);
    
    const url = `${this.url}deletes/?&queue_name=${queue}`;
    const body = { interfaces: this.orginalData.map(x => x.originalInterface) };
    return this.http
      .delete(url, {
        headers: this.headers,
        body: body,
      })
  }

  public create(queue: string): Observable<any> {
    if(this.data.length === 0) return of<Agent[]>([]);

    const url = `${this.url}posts/?queue_name=${queue}`;
    const body = { 
      members: this.data.map(x => {
        const agent = { 
          ...x, 
          paused: x.available ? 0 : 1,
          interface: padInterface(x.interface!),
          penalty: 0
        };
        delete agent.available;
        delete agent.originalInterface;
        return agent;
      }) 
    };
    return this.http
      .post(url, body, {
        headers: this.headers
      })
      .pipe(
        map((response: any) => {          
          this.data = response.members.map(mapAgent());
          this.orginalData = [...this.data];
          return this.data;
        })
      );
  }
}

function mapAgent(): any {
  return (item: any) => {
    delete item.creation_status;
    return ({
      ...item,
      originalInterface: item.interface,
      available: item.paused === 0 ? true : false,
      interface: parseInterface(item.interface)
    });
  };
}

function parseInterface(i: string) {
  return i.replace('PJSIP/', '').replace('@TELNYX', '').substring(1);
}

function padInterface(i: string) {
  return `PJSIP/1${i}@TELNYX`;
}