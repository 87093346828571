import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '../core/services/session.service';
import { FormGroup, FormArray } from '@angular/forms';
import { FileInfo, FileSelectComponent, SelectEvent } from '@progress/kendo-angular-upload';
import { MenuOptionService } from './services/menu-option.service';
import { FileUploadService } from '../core/services/file-upload.service';
import { Options } from '../core/enums/options';
import { delay, distinctUntilChanged, finalize, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { triggerAnimationShowHide } from './common/expansion.animate';
import { LocalStorageService } from '../core/services/local-storage.service';
import { AppSettings } from 'src/assets/config/app-settings';
import { Activity } from './components/queue/services/activity.service';
import { MenuService } from './services/menu-service';
import {
  newForwardOptionForm,
  createFileForm,
  createMenuForm,
  newMmsOptionForm,
  createOptionForm,
  newPromptOptionForm,
  newQueueOptionForm,
  newRepeatOptionForm,
  newSmsOptionForm,
  newVmailOptionForm,
  getFilename,
  optionFormToModel,
  newCreateLinkOptionForm,
  createOptionForms,
  menuFormToModel,
} from './services/menu-form.service';
import { MenuForm, OptionForm, OptionTypes, FileForm, FileState, Batch, Menu } from './models/menu';
import { MenuHelpService } from './services/menu-help.service';
import { ToasterService } from '../shared/notification-toast/toaster.service';
import { ChangeFileEvent } from './models/events';
import { EventsService } from '../core/services/events.service';
import { BaseComponent } from '../core/common/base.component';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  animations: [triggerAnimationShowHide],
})
export class MenusComponent extends BaseComponent implements OnInit {
  @ViewChild('sayMp3') sayMp3!: FileSelectComponent;

  public menuForms = new FormArray<FormGroup<MenuForm>>([]);
  public menuForm = createMenuForm();
  public showLoader = false;
  public opened = false;
  public activities = new Array<Activity>();
  public TypeOption = false;
  public option = Options;
  public optionTypes = OptionTypes;
  public userName: string | undefined;
  public userImages: Array<FileInfo> | undefined;
  public listItems: Array<string> = ['woman', 'man'];
  private lastCheckAll = false;
  private optionFormCache: FormGroup<OptionForm> | null = null;
  private menuFormCache: FormGroup<MenuForm> | null = null;
  public batch$: Batch[] = [];

  constructor(
    private session: SessionService,
    private toaster: ToasterService,
    private optionService: MenuOptionService,
    private uploadService: FileUploadService,
    private dialogService: DialogService,
    private localStorage: LocalStorageService,
    public settings: AppSettings,
    private menuService: MenuService,
    public help: MenuHelpService,
    private events: EventsService,
    private cdRef: ChangeDetectorRef) {
    super();
  }

  get options() {
    return this.optionForms.controls;
  }

  public get optionForms() {
    return this.menuForm?.controls.menuOptions!;
  }

  public get batch(): any[] {
    return (
      this.batch$.map((x) => {
        return {
          name: x.form.value.name,
          state: x.form.value.state,
          file: x.form.value.file,
        };
      }) ?? []
    );
  }

  public get optionsChecked() {
    return (
      this.optionForms.controls.filter((optionForm: FormGroup<OptionForm>) => optionForm.controls.delete?.value) ?? []
    );
  }

  ngOnInit(): void {
    this.getNumberMenus();
    this.events.defaultNumberChanged$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((numberId) => {
        this.getNumberMenus(numberId);
    });
  }

  getLastSelectedMenuId() {
    return this.localStorage.getItem<string>('lastSelectedMenuId').pipe(takeUntil(this.destroyed$));
  }

  getNumberMenus(numberId: string | null = null) {
    this.showLoader = true;
    const customerId = this.session.user?.customer?._id;
    if (!numberId) numberId = this.session.user?.selectedNumber?._id!;

    this.menuService
      .get(customerId!, numberId)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.showLoader = false)),
        switchMap((menuForms: FormArray<FormGroup<MenuForm>>) => {
          this.menuForms = menuForms;
          return this.getLastSelectedMenuId().pipe(takeUntil(this.destroyed$), distinctUntilChanged());
        })
      )
      .subscribe({
        next: (menuId: string | null) => {
          const menuIndex = menuId ? this.menuForms.controls.findIndex((x) => x.controls._id!.value === menuId) : 0;

          if (menuIndex > -1 && this.menuForms.controls[menuIndex])
            this.menuForm = this.menuForms.controls[menuIndex] ?? this.menuForms.controls[0];
          else if (this.menuForms.controls.length > 0) this.menuForm = this.menuForms.controls[0];
          else this.menuForm = createMenuForm();
          this.menuFormCache = createMenuForm(menuFormToModel(this.menuForm));
        },
      });
  }

  addSayMp3(e: ChangeFileEvent, index: number | null = null) {
    const fileInfo = e.selectEvent.files[0];
    this.serializeFile(e.selectEvent, async (progressEvent) => {
      const customerId = this.session.user?.customer?._id!;
      const extension = fileInfo.name?.match(/\.[0-9a-z]+$/i)![0].replace('.', '')!;

      const isFileAllowed = this.settings.fileRestrictions?.mp3Files.allowedExtensions?.includes(extension);
      if (!isFileAllowed) return;
      e.fileForm.setValue({
        name: fileInfo.name!,
        url: progressEvent.target?.result as string,
        state: FileState.added,
        file: null,
      });

      if (index !== null) this.options[index].controls.sayMp3File?.patchValue(null);

      await fetch(e.fileForm.controls.url?.value!)
        .then((res) => res.blob())
        .then((blob) => {
          (blob as any).name = e.fileForm.controls.name?.value!;
          this.upsertBatch({
            form: e.fileForm,
            observable$: this.uploadService.upload(customerId, blob, extension, e.filepath),
          });
          e.serializeCallback?.(progressEvent, blob);
        });
    });
  }

  logWait(optionForm: FormGroup<OptionForm>) {
    if (optionForm.controls?.type?.value === 'queue') {
      return optionForm.controls?.wait?.value.map((x) => {
        return {
          name: x.play?.name,
          value: x.play?.file,
          state: x.play?.state,
        };
      });
    }
    return [];
  }

  deleteSayMp3(fileForm: FormGroup<FileForm>) {
    const isHttpUrl = (str: string) => {
      const urlRegex = /http(s)?:\/\/\S+/i;
      return urlRegex.test(str);
    };
    const url = fileForm.controls.url?.value!;
    if (isHttpUrl(url)) {
      this.upsertBatch({ form: fileForm, observable$: this.uploadService.delete(url) });
    }
    fileForm.controls.name?.setValue(null);
    fileForm.controls.url?.setValue(null);
    fileForm.controls.file?.setValue(null);
    fileForm.controls.state?.setValue(FileState.none);
  }

  upsertBatch(batch: Batch) {
    const index = this.batch$.findIndex((x) => x.form.controls.name?.value === batch.form.controls.name?.value);
    if (index > -1) {
      this.batch$[index] = batch;
    } else {
      this.batch$.push(batch);
    }
  }

  deleteImage($event: { optionForm: FormGroup<OptionForm>; fileForm: FormGroup<FileForm> }) {
    const optionForm = $event.optionForm;
    const fileForm = $event.fileForm;
    fileForm.controls.state?.setValue(FileState.deleted);
    optionForm.controls.images?.controls.splice(optionForm.controls.images?.controls.indexOf(fileForm), 1);
    this.upsertBatch({ form: fileForm, observable$: this.uploadService.delete(fileForm.controls.url?.value!) });
  }

  addImage($event: { selectEvent: SelectEvent; optionForm: FormGroup<OptionForm>; callback?: (response: FormGroup<FileForm>) => void }) {
    const fileInfo = $event.selectEvent.files[0];
    this.serializeFile($event.selectEvent, async (progressEvent) => {
      const fileForm = createFileForm(fileInfo.name!, progressEvent.target?.result as string, FileState.added);
      $event.optionForm.controls.images?.push(fileForm);
      await fetch(fileForm.controls.url?.value!)
        .then((res) => res.blob())
        .then((blob) => {
          const customerId = this.session.user?.customer?._id!;
          const extension = fileForm.controls.name?.value?.match(/\.[0-9a-z]+$/i)![0].replace('.', '')!;
          (blob as any).name = fileForm.controls.name?.value;
          this.upsertBatch({ form: fileForm, observable$: this.uploadService.upload(customerId, blob, extension), callback: $event.callback });
        });
    });
  }

  serializeFile(e: SelectEvent, callback?: (e: ProgressEvent<FileReader>) => void) {
    this.showLoader = true;
    const fileInfo = e.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.showLoader = false;
    };
    reader.onload = (e) => callback?.(e);
    reader.readAsDataURL(fileInfo.rawFile as Blob);
  }

  public removeSelectedFile(file: string): void {
    console.log(file);
  }

  optionTypeChange(option: Options, index: number) {
    let form = this.optionForms.controls[index];
    switch (option) {
      case Options.forward:
        this.optionForms.controls[index] = newForwardOptionForm(form);
        break;
      case Options.mms:
        this.optionForms.controls[index] = newMmsOptionForm(form);
        break;
      case Options.prompt:
        this.optionForms.controls[index] = newPromptOptionForm(form);
        break;
      case Options.queue:
        this.optionForms.controls[index] = newQueueOptionForm(form);
        break;
      case Options.repeat:
        this.optionForms.controls[index] = newRepeatOptionForm(form);
        break;
      case Options.sms:
        this.optionForms.controls[index] = newSmsOptionForm(form);
        break;
      case Options.vmail:
        this.optionForms.controls[index] = newVmailOptionForm(form);
        break;
      case Options.link:
        this.optionForms.controls[index] = newCreateLinkOptionForm(form);
        break;
    }
    this.optionForms.controls[index].controls.type?.markAsDirty();
  }

  resequence(iteration: number, index: number) {
    if (index == 0 && iteration == -1) {
      iteration = this.options.length;
    }
    if (index == this.options.length - 1 && iteration == 1) {
      iteration = 0 - (this.options.length - 1);
    }
    this.updateOptionSequence(index + iteration, index);
  }

  updateOptionSequence(index?: number, moveFromIndex?: number) {
    this.showLoader = true;

    if (moveFromIndex !== undefined && index !== undefined) {
      const previous = this.optionForms.controls.splice(moveFromIndex, 1)[0];
      this.optionForms.controls.splice(index, 0, previous);
    }

    const originalSequence = this.optionForms.controls.map((x) => x.controls.digits?.value) + '';
    this.optionForms.controls.forEach((option, index) => option.controls.digits!.setValue(index + 1));
    const newSequence = this.optionForms.controls.map((x) => x.controls.digits?.value) + '';

    if (originalSequence === newSequence) {
      this.showLoader = false;
      return;
    }

    const menuId = this.menuForm?.controls._id?.value!;
    this.optionService
      .saveSequence(menuId, this.optionForms)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => {
        this.menuForm.controls.menuOptions = x;
        this.toaster.success('Your Menu changes have been saved 🚀');
        this.showLoader = false;
        this.optionFormCache = null;
      });
  }

  addOption() {
    if (this.optionForms.controls.length >= 9) {
      this.toaster.error('You have reached the maximum number of (9) options for this menu.');
      return;
    }
    this.showLoader = true;
    const newOptionForm = createOptionForm(
      {
        _id: null,
        digits: this.optionForms.controls.length + 1,
        type: Options.sms,
        say: null,
        sayMp3: null,
        isSayMp3: false,
        sms: null,
        smsFromNumber: null,
      },
      true
    );
    this.optionService
      .add(this.menuForm?.controls._id?.value!, newOptionForm)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((optionForm) => {
        this.showLoader = false;

        this.optionForms.controls
          .filter((x) => !x.controls._id?.value)
          .forEach((form: any, index) => {
            form.controls.type.disable();
            this.collapseOption(form, index);
          });

        if (optionForm) {
          this.optionForms.controls.push(optionForm);
          this.expandOption(optionForm);
        }
      });
  }

  menuChange($event: { _id: string } | null) {
    if ($event?._id) {
      this.localStorage.setItem('lastSelectedMenuId', $event._id);
    } else {
      this.localStorage
        .getItem<string>('lastSelectedMenuId')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((menuId) => {
          if (menuId) {
            const menuIndex = this.menuForms.controls.findIndex((x) => x.controls._id!.value === menuId);
            if (menuIndex > -1 && this.menuForms.controls[menuIndex])
              this.menuForm = createMenuForm(menuFormToModel(this.menuForms.controls[menuIndex]));
            else if (this.menuForms.controls.length > 0) {
              this.menuForm = this.menuForms.controls[0];
            } else {
              this.menuForm = createMenuForm();
            }
          } else {
            this.menuForm = this.menuForms.controls[0];
          }
        });
    }
  }

  successCallback() {
    console.log('Testing notification callback');
  }

  saveOption(optionForm: FormGroup<OptionForm>) {
    this.showLoader = true;
    const menuId = this.menuForm.controls._id?.value!;
    const numberId = this.session.user?.selectedNumber?._id!;
    const save = (menuId: string, optionForm: FormGroup<OptionForm>) => {
      this.optionService
        .save(numberId, menuId, optionForm)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (savedOptionForm: FormGroup<OptionForm>) => {
            const formIndex = this.optionForms.controls.findIndex(
              (f) => f.controls._id?.value === savedOptionForm.controls._id?.value
            );
            savedOptionForm.controls.sStatus?.setValue(true);
            savedOptionForm.controls.tabIndex?.setValue(optionForm.controls.tabIndex?.value!);
            this.optionForms.controls[formIndex].setValue(savedOptionForm.getRawValue());
            this.optionFormCache = createOptionForm(optionFormToModel(savedOptionForm));
            this.batch$ = [];
          },
          complete: () => {
            this.toaster.success('Your Option changes have been saved. Time for tea!', 'Ok', this.successCallback);
            this.showLoader = false;
            this.events.refreshMiniPlayer();            
          },
        });
    };

    if (this.batch$.length) {
      forkJoin(this.batch$.map((x) => x.observable$))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((x) => {
          x.forEach((response, index) => {
            const fileForm = this.batch$[index].form;
            if (fileForm.controls.state?.value === FileState.deleted) {
              fileForm.controls.name?.setValue(null);
              fileForm.controls.url?.setValue(null);
              fileForm.controls.file?.setValue(null);
              fileForm.controls.state?.setValue(FileState.none);
            } else if (fileForm.controls.state?.value === FileState.added) {
              const fileName = getFilename(response.results?.link!);
              fileForm.controls.name?.setValue(fileName);
              fileForm.controls.url?.setValue(response.results?.link!);
              fileForm.controls.file?.setValue(fileName);
              fileForm.controls.state?.setValue(FileState.none);
            }
            this.batch$[index].callback?.(fileForm);
          });
          save(menuId, optionForm);
        });
    } else {
      save(menuId, optionForm);
    }
  }

  deleteOption(optionForm: FormGroup<OptionForm>) {
    optionForm.controls.delete?.setValue(true);
    this.deleteOptions();
  }

  deleteOptions() {
    this.showLoader = true;
    this.optionService
      .delete(this.menuForm.controls._id?.value!, this.optionsChecked)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.menuForm.controls.menuOptions = createOptionForms(response.options);
          this.toaster.success('Your selected options have been removed 😊');
        },
        complete: () => {
          this.showLoader = false;
        },
        error: () => this.toaster.error('There was an error deleted the selected Options(s).'),
      });
  }

  checkAll() {
    this.options.forEach((option: FormGroup<OptionForm>) => {
      option.controls.delete?.setValue(!this.lastCheckAll);
    });
    this.lastCheckAll = !this.lastCheckAll;
  }

  deleteMenu() {
    this.menuService
      .delete(this.menuForm)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getNumberMenus();
        this.toaster.success('Menu was deleted! 🚀');
      });
  }

  addMenu() {
    const menu = new Menu();
    menu.name = 'New IVR Menu';
    menu.greeting =
      'Welcome to the new IVR Menu, press 1 to receive a text with directions, 2 to receive a text for online ordering, 3 to speak to team member...';
    menu.sayIncorrectKey = "You've pressed an invalid option, please try again.";
    this.menuForm = createMenuForm(menu);
  }

  saveMenu() {
    this.showLoader = true;
    const save = () => {
      const numberId = this.session.user!.selectedNumber?._id!;
      this.menuService
        .save(numberId!, this.menuForm)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.batch$ = [];
            this.localStorage.setItem('lastSelectedMenuId', this.menuForm.controls._id?.value!);
            this.getNumberMenus();
            this.toaster.success('Your Menu changes have been saved. Time for tea!');
          },
          complete: () => {
            this.showLoader = false;            
            this.events.refreshMiniPlayer();
          },
        });
    };

    if (this.batch$.length) {
      forkJoin(this.batch$.map((x) => x.observable$))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((x) => {
          x.forEach((response, index) => {
            const fileForm = this.batch$[index].form;
            if (fileForm.controls.state?.value === FileState.deleted) {
              fileForm.controls.name?.setValue(null);
              fileForm.controls.url?.setValue(null);
              fileForm.controls.file?.setValue(null);
              fileForm.controls.state?.setValue(FileState.none);
            } else if (fileForm.controls.state?.value === FileState.added) {
              const fileName = getFilename(response.results?.link!);
              fileForm.controls.name?.setValue(fileName);
              fileForm.controls.url?.setValue(response.results?.link!);
              fileForm.controls.file?.setValue(fileName);
              fileForm.controls.state?.setValue(FileState.none);
            }
            this.batch$[index].callback?.(fileForm);
          });
          save();
        });
    } else {
      save();
    }
  }

  expandOption(optionForm: FormGroup<OptionForm>) {
    this.batch$ = [];
    this.optionFormCache = createOptionForm(optionFormToModel(optionForm));
    this.optionForms.controls.forEach((form) => {
      if (form.controls._id !== optionForm.controls._id) {
        form.controls.type?.disable();
        form.controls.sStatus?.patchValue(false);
      } else {
        form.controls.type?.enable();
        form.controls.sStatus?.patchValue(true);
      }
    });
  }

  collapseOption(optionForm: FormGroup<OptionForm>, index: number) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Any new changes made will be lost, are you sure you would like to cancel?',
      actions: [{ text: 'No' }, { text: 'Yes', themeColor: 'primary' }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        if (result.text === 'Yes') {
          this.batch$ = [];
          optionForm.controls.type?.disable();
          optionForm.controls.sStatus?.setValue(false);
          if (this.optionFormCache) {
            of(true)
              .pipe(delay(900))
              .subscribe(() => (this.optionForms.controls[index] = this.optionFormCache!));
          }
        }
      }
    });
  }

  public menuToggleEdit($event: boolean) {
    this.batch$ = [];
    if(!$event && this.menuFormCache && this.menuForm.value._id === this.menuFormCache?.value._id) {
      const menuFormModel = menuFormToModel(this.menuFormCache);
      this.menuForm = createMenuForm(menuFormModel);
      this.cdRef.detectChanges()
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!(event?.target as any)?.getAttribute('help')) {
      this.help.closePopup();
    }
  }
}
