import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { ChangeFileEvent } from 'src/app/menus/models/events';
import { FileForm, OptionForm, WaitForm } from 'src/app/menus/models/menu';

@Component({
  selector: 'queue-on-hold',
  templateUrl: './on-hold.component.html',
  styleUrls: ['./on-hold.component.scss']
})
export class OnHoldComponent {
  public formGroup: FormGroup<WaitForm> | undefined;
  public _optionForm = new FormGroup<OptionForm>({});

  @Input() set optionForm(value: FormGroup<OptionForm>) {
    this._optionForm = value ?? new FormGroup<OptionForm>({});
    this._optionForm.controls.wait!.controls[0].controls.isSayMp3?.setValue(true);
    this.formGroup = this._optionForm.controls.wait!.controls[0];
  }
  @Input() mp3FilesRestriction!: FileRestrictions;
  @Output() changeFile = new EventEmitter<ChangeFileEvent>();
  @Output() deleteMp3 = new EventEmitter<FormGroup<FileForm>>();
  @Output() addMp3 = new EventEmitter<{ e: ChangeFileEvent, index: number | null }>();

  private docClickSubscription: Subscription = new Subscription();

  constructor(private session: SessionService) { }

  public ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  onDeleteWaitMp3() {
    this.deleteMp3.emit(this.getFileForm(this.formGroup!));
  }

  public getFileForm(waitForm: FormGroup<WaitForm>) {
    return waitForm.controls.play!;
  }

  onWaitMp3ChangeFile(e: SelectEvent) {
    if (this.formGroup) {
      const waitId = this.formGroup.controls._id?.value!;
      const customerId = this.session.user?.customer?._id;
      this.changeFile.emit({
        selectEvent: e,
        fileForm: this.formGroup!.controls.play!,
        serializeCallback: undefined,
        uploadCallback: undefined,
        filepath: `${customerId}/moh/${waitId}/${e.files[0].name}`
      });
    }
  }
}