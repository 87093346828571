import { Component, Input, OnInit } from '@angular/core';
import { FileState } from '../../models/menu';

@Component({
  selector: 'rg-mp3',
  templateUrl: './rg-mp3.component.html',
  styleUrls: ['./rg-mp3.component.scss']
})
export class RgMp3Component implements OnInit {
  @Input() mp3: string | null | undefined = null;
  @Input() label: string | null = null;
  @Input() filename: string | null = null;
  @Input() state: FileState = FileState.none;
  @Input() playerPosition: string = 'bottom';
  
  public fileState = FileState;
  public isStreamingMp3 = false;

  constructor() { }

  ngOnInit(): void { }
}
