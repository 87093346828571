import { ElementRef, Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { HelpComponent } from 'src/app/core/components/help/help.component';

@Injectable({ providedIn: 'root' })
export class MenuHelpService {
  private popupRef: PopupRef | null = null;

  private helps = [
    {
      key: 'help',
      title: '🆘 Click Icons for HelpClick Icons for Help',
      image: 'assets/media/menus-screen/help.gif',
      text: 'To receive help in any of the menu options please click any of the icons for a help pop up screen. Please review the animated gif for an example.'
    },
    {
      key: 'say',
      title: '🔊 Say Prompt',
      image: 'assets/media/menus-screen/say-img.gif',
      text: "When caller presses this digit, IVR will read out what's in the Say field to the caller."
    },
    {
      key: 'smsFrom',
      title: '💬 SMS from Number',
      image: 'assets/media/menus-screen/sms-text-from.svg',
      text: "The from number that will be used when sending an SMS to the caller."
    },
    {
      key: 'sms',
      title: '💬 SMS Text',
      image: 'assets/media/menus-screen/sms-text.svg',
      text: "The text message caller will receive when they press this digit."
    },
    {
      key: 'incorrect-key',
      title: '#️⃣ Incorrect Key Pressed',
      image: null,
      text: "When caller presses a digit that is not in the menu, IVR will read out or play the mp3 nn the Incorrect Key field to the caller."
    },
    {
      key: 'voice',
      title: '🗣️ Voice',
      image: null,
      text: "Voice used to read out the Say fields."
    },
    {
      key: 'vmail-from',
      title: '📞 Voicemail From',
      image: null,
      text: "The From number used when sending an SMS notification that you have a voicemail."
    },
    {
      key: 'vmail-to',
      title: '💬 Voicemail To #',
      image: null,
      text: "The number used when sending an SMS notification that you have a voicemail."
    },
    {
      key: 'vmail-to-email',
      title: '📨 Voicemail To Email',
      image: null,
      text: "The email used when sending an email notification that you have a voicemail."
    },
    {
      key: 'forward',
      title: '⏩ Forward Number',
      image: null,
      text: "When caller presses this digit, IVR will forward the call to the number in the Forward field."
    },
    {
      key: 'say-mp3',
      title: '🔊 Mp3 Prompt',
      image: null,
      text: "When caller presses this digit, IVR will play Mp3 to the caller."
    }
  ];

  lastAnchor: ElementRef | HTMLElement | null = null;
  constructor(private popupService: PopupService) { }

  public togglePopup(anchor: ElementRef | HTMLElement, key: string): void {
    if (this.popupRef) {
      this.closePopup();
      if (this.lastAnchor !== anchor) {
        this.openPopup(anchor, key);
      };
    } else {
      this.openPopup(anchor, key);
    }
    this.lastAnchor = anchor;
  }

  public closePopup() {
    this.popupRef?.close();
    this.popupRef = null;
  }

  private openPopup(anchor: ElementRef<any> | HTMLElement, key: string) {
    this.popupRef = this.popupService.open({
      anchor: anchor,
      content: HelpComponent,
    });
    (this.popupRef.content.instance as HelpComponent).context = this.helps.find(x => x.key === key)!;
  }
}