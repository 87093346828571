import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/assets/config/app-settings';
import { GridService } from './grid.service';

@Injectable()
export class AgentsService extends GridService {
  constructor(http: HttpClient, settings: AppSettings) {
    super(http, `queues/agents/`, settings);
  }

  createAgent(requestData: any) {
    return this.http.post(`${this.settings.apiBaseUrl}/api/queues/agents/`, requestData);
  }

  updateAgents(requestData: any) {
    return this.http.put(`${this.settings.apiBaseUrl}/api/queues/agents/`, requestData);
  }

  deleteAgent(requestData: any) {
    const params = new HttpParams({ fromObject: requestData });
    return this.http.delete(`${this.settings.apiBaseUrl}/api/queues/agents/`, { params });
  }
}
