import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { FileForm, OptionForm, RgFormGroup } from '../../models/menu';
import { ChangeFileEvent } from '../../models/events';

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss'],
})
export class VoicemailComponent implements OnInit {
  form = new FormGroup<OptionForm>({});
  @Input() set formGroup(value: any) {
    this.form = value;
  }
  @Input() mp3FilesRestriction!: FileRestrictions;

  @Output() save = new EventEmitter<FormGroup<OptionForm>>();
  @Output() help = new EventEmitter<{ anchor: ElementRef | HTMLElement| any, key: string }>();
  @Output() delete = new EventEmitter<FormGroup<OptionForm>>();
  @Output() deleteFile = new EventEmitter<FormGroup<FileForm>>();
  @Output() changeFile = new EventEmitter<ChangeFileEvent>();

  ngOnInit(): void {}

  onSave() {
    this.save.emit(this.form);
   }

  public sayMp3Selected(e: FileInfo[]): void {
  }

  public sayByeMp3Selected(e: FileInfo[]): void {
  }

  onDeleteGreetingMp3() {
    this.deleteFile.emit(this.form.controls.vmailSayMp3);
    this.form.controls.sayMp3File?.patchValue(null);
  }

  onMp3ChangeFile(e: SelectEvent) {
    this.changeFile.emit({ selectEvent: e, fileForm: this.form.controls.vmailSayMp3! });
  }

  public infoContent = `
  This will be the mobile number that will receive an SMS, notifying that a voicemail was received
  with a link to play the voicemail.
  `;

  public voiceMailFromContent = `
  This is the mobile number sending the link of the voicemail to the forward number
  `;

  public showForward = false;
  public showFrom = false;
}
