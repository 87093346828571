import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/assets/config/app-settings';
import { Option, OptionForm } from '../models/menu';
import { FormArray, FormGroup } from '@angular/forms';
import { map } from 'rxjs';
import { createOptionForm, createOptionForms, optionFormToModel } from './menu-form.service';
import { SessionService } from 'src/app/core/services/session.service';

@Injectable({ providedIn: 'root' })
export class MenuOptionService {
  constructor(private http: HttpClient, private settings: AppSettings, private session: SessionService) { }

  save(numberId: string, menuId: string, optionForm: FormGroup<OptionForm>) {
    const model = { ...optionFormToModel(optionForm), provider: this.session.user?.selectedNumber?.provider };
    return this.http.patch<Option>(
      `${this.settings.apiBaseUrl}/api/menuoptions/patch?numberId=${numberId}&menuId=${menuId}&menuOptionId=${optionForm.controls._id?.value}`, model)
      .pipe(map((option: Option) => createOptionForm(option)));
  }

  add(menuId: string, optionForm: FormGroup<OptionForm>) {
    return this.http.post(`${this.settings.apiBaseUrl}/api/menuoptions/add?menuId=${menuId}`, optionFormToModel(optionForm))
      .pipe(map((option: Option) => createOptionForm(option)));
  }

  delete(menuId: string, options: FormGroup<OptionForm>[]) {
    const optionsToDelete: Option[] = [];
    options.forEach(option => optionsToDelete.push(optionFormToModel(option)));

    return this.http.post<{ options: Option[] }>(
      `${this.settings.apiBaseUrl}/api/menuoptions/deletes?menuId=${menuId}`, { optionsToDelete });
  }

  saveSequence(menuId: string, options: FormArray<FormGroup<OptionForm>>) {
    return this.http.patch<Option[]>(`${this.settings.apiBaseUrl}/api/menuoptions/digits/patch?menuId=${menuId}`, options.getRawValue())
      .pipe(map((options: Option[]) => createOptionForms(options)));
  }
}
