@if (formGroup) {
  <form [formGroup]="formGroup!" class="p-6">
    <div class="row border row gx-0">
      <div class="col" formGroupName="play">
        @if (!formGroup!.value.play?.url || formGroup!.value.play?.state === 'deleted') {
          <kendo-fileselect
            class=""
            formControlName="file"
            #sayMp3
            [multiple]="false"
            [restrictions]="{}"
            style="width: 10;"
            (select)="onWaitMp3ChangeFile($event)"
            [restrictions]="mp3FilesRestriction">
          </kendo-fileselect>
        }
        @if (formGroup!.value.play?.url && formGroup!.value.play?.state !== 'deleted') {
          <rg-mp3
            [state]="formGroup!.value.play?.state!"
            [filename]="formGroup!.value.play?.name!"
            [mp3]="formGroup!.value.play?.url"
            style="width: 100%"
            class="mb-0"
            >
          </rg-mp3>
        }
      </div>
      @if (formGroup!.value.play?.url && formGroup!.value.play?.state !== 'deleted') {
        <div class="col-auto">
          <button kendoButton
            class="btn-delete file-delete ms-0"
            (click)="deleteMp3.emit(getFileForm(formGroup!))"
            type="button">
            <span class="btn-icon fa-duotone fa-trash-can"></span>
          </button>
        </div>
      }
    </div>
  </form>
}