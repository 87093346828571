import { Component, ViewChild, ElementRef, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppSettings } from 'src/assets/config/app-settings';
import { EventsService } from '../../services/events.service';
import { SessionService } from '../../services/session.service';
import { BaseComponent } from '../../common/base.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'rgrid-mini-player',
  templateUrl: './mini-player.component.html',
  styleUrls: ['./mini-player.component.scss']
})
export class MiniPlayerComponent extends BaseComponent implements OnInit {
  private __id: string = '';
  @Input() set _id(value: string) {
    if(!value) return;
    if(this._file) throw new Error('Cannot set both _id and _file');
    this.__id = value;    
    this.updateAudioSourceById();
  }

  private _file: string = '';
  @Input() set file(value: string) {
    if(!value) return;
    if(this.__id) throw new Error('Cannot set both _id and _file');
    this._file = value;
    this.updateAudioSourceByFile();
  }

  public isPlaying = false;
  public progress = 0;
  public currentTime = 0;
  public duration = 0;
  public audio = new Audio();
  
  constructor(private settings: AppSettings, private session: SessionService, private events: EventsService) {
    super();
  }
  
  ngOnInit(): void {
    this.audio.ontimeupdate = () => this.updateProgress();
    this.audio.onloadedmetadata = () => this.setDuration();
    this.audio.onended = () => {
      this.progress = 0;
      this.isPlaying = false;
    };

    this.events.refreshMiniPlayer$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      this.updateAudioSourceById();
      this.updateAudioSourceByFile();
      this.isPlaying = false;
    });

    this.events._miniPlayerPlayButtonClicked$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      if(this.isPlaying) {
        this.isPlaying = false;
        this.audio.pause();
      }
    });
  }

  ngAfterViewInit(): void {
    this.updateAudioSourceById();
  }
  
  togglePlay() {
    if(this.isPlaying)
      this.audio?.pause();
    else {
      this.audio?.play();
      this.events.miniPlayerPlayButtonClicked({ _id: this.__id, file: this._file });
    }

    this.isPlaying = !this.isPlaying;
  }

  updateProgress() {
    this.progress = (this.audio.currentTime! / this.audio?.duration! || 0) * 100;
    this.currentTime = this.audio?.currentTime!;
  }

  setDuration() {
    this.duration = this.audio.duration;
  }

  
  updateAudioSourceById() {
    if (this.__id) {
      this.audio.src = this.getWavUrl(this.__id);
    }
  }
  
  updateAudioSourceByFile() {
    if (this._file) {
      this.audio.src = this._file;
    }
  }

  getWavUrl(_id: string) {
    const timestamp = new Date().getTime();
    return `${this.settings.audioUrl}/${this.session.user?.customer?._id}/mp3/${_id}.wav?timestamp=${timestamp}`;
  }

  rewindAudio() {
    const rewindAmount = 10; // Seconds to rewind
    const audio = this.audio;
    audio.currentTime = Math.max(0, audio.currentTime - rewindAmount);
  }  
}
