<kendo-tabstrip class="r-tabs mt-5 mb-4 pb-0 shadow-sm">
  <kendo-tabstrip-tab title=" " [selected]="true">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-question me-1 icon fs-4"
        aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-6">
        <div class="d-flex flex-column">
          <p>
            The <em>Repeat</em> 🔁 feature in your restaurant's IVR system allows callers to hear the menu options again by
            pressing a designated digit on their phone keypad. This can be helpful for customers who may have missed a
            particular item or need to hear the options again for clarity.
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<form class="k-form relaygrid-form row clearboth mt-2"
  [formGroup]="form">

  <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
    <button kendoButton
      themeColor="primary"
      class="btn btn-light-primary btn-icon icon-35"
      (click)="onSave()">
      <em class="fa-duotone fa-floppy-disks"></em>
      <span class="ms-2 label10">Save</span>
    </button>
    <button kendoButton
      class="btn btn-light-danger ms-3 btn-icon icon-35"
      (click)="onDelete()">
      <em class="fa-duotone fa-trash-can"></em>
      <span class="ms-2 label11">Delete</span>
    </button>
  </div>
</form>