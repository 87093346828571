<kendo-tabstrip class="r-tabs mb-4 pb-0 shadow-sm">
  @if (_menuForm) {
    <kendo-tabstrip-tab [selected]="true">
      <ng-template kendoTabTitle> <em class="fa-duotone fa-phone-volume"></em>&nbsp; Menu </ng-template>
      <ng-template kendoTabContent>
        <div class="g-5 g-xl-8 d-flex flex-row flex-wrap p-6 pb-0">
          @if (!menuShow) {
            <kendo-floatinglabel
              text="Select Menu"
              class="col-md-5 pe-5 form-group icon"
              style="z-index: 1000"
              >
              <kendo-dropdownlist
                class="col-md-6"
                style="width: 100%; height: 45px"
                [data]="menuForms.value"
                textField="name"
                valueField="_id"
                [(ngModel)]="selectedMenu"
                (valueChange)="onMenuChange($event)"
                >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <em class="me-2 mt-1 fs-4 fa-duotone fa-bars"></em>&nbsp;{{ dataItem?.name }}
                </ng-template>
              </kendo-dropdownlist>
            </kendo-floatinglabel>
          }
          @if (menuShow) {
            <form [formGroup]="_menuForm" class="k-form col-md-5">
              <kendo-formfield class="k-form-fieldset pe-5">
                <kendo-floatinglabel text="Menu Name" class="form-group icon">
                  <kendo-textbox formControlName="name" [clearButton]="true" #name>
                    <ng-template kendoTextBoxPrefixTemplate>
                      <em class="ms-3 fs-4 fa-thin fa-input-text"></em>
                    </ng-template>
                  </kendo-textbox>
                </kendo-floatinglabel>
                <kendo-formerror>IVR Menu Name is required</kendo-formerror>
              </kendo-formfield>
            </form>
          }
          <div class="k-form-buttons col-5 px-3 m-0 d-flex justify-content">
            @if (!menuShow && _menuForm.controls._id?.value) {
              <button
                kendoButton
                themeColor="primary"
                class="btn btn-light-primary btn-icon icon-35 mb-4 mt-6"
                (click)="onToggleEdit()"
                title="Edit Menu"
                >
                <em class="fa-duotone fa-pen-to-square fs-3"></em>
                <span class="ms-2 label3">Edit</span>
              </button>
            }
            @if (!menuShow) {
              <button
                kendoButton
                themeColor="primary"
                class="btn btn-light-primary btn-icon icon-35 mb-4 mt-6"
                (click)="onAddMenu()"
                title="Add Menu"
                >
                <em class="fa-duotone fa-square-plus fs-3"></em>
                <span class="ms-2 label4">Add</span>
              </button>
            }
            @if (menuShow) {
              <button
                kendoButton
                themeColor="primary"
                class="btn btn-light-info btn-icon icon-35 mb-4"
                (click)="onCancelAddEdit()"
                title="Cancel"
                >
                <em class="fa-duotone fa-ban fs-3"></em>
                <span class="ms-2 label8">Cancel</span>
              </button>
            }
          </div>
          <!-- <div class="col-12" [@animationShowHide]="menuShow" id="edit-form">
          <form class="k-form relaygrid-form row" [formGroup]="_menuForm">
            <div #IncorrectKey class="m-0 col-md-6 px-3 toggle-fieldset say-fieldset align-self-start">
              <kendo-formfield
                class="k-form-fieldset text-fieldset textarea-height"
                *ngIf="!_menuForm.get('isSayIncorrectKeyMp3')?.value"
                >
                <kendo-floatinglabel text="Incorrect Key Pressed" class="form-group custom-icon help-icon">
                  <kendo-textarea formControlName="sayIncorrectKey" [rows]="1" #sayBye class="k-focus">
                    <kendo-textarea-suffix class="custom-styling d-flex">
                      <em
                        class="m-3 fs-4 fa-duotone fa-triangle-exclamation"
                        help="true"
                        (click)="help.emit({ anchor: IncorrectKey, key: 'incorrect-key' })"
                      ></em>
                    </kendo-textarea-suffix>
                  </kendo-textarea>
                </kendo-floatinglabel>
                <kendo-formerror>IncorrectKey text is required if Text swtich is on.</kendo-formerror>
              </kendo-formfield>
              <ng-container *ngIf="_menuForm.get('isSayIncorrectKeyMp3')?.value">
                <kendo-formfield
                  *ngIf="!_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value"
                  class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload m-0 mb-10"
                  style="width: 100%"
                  >
                  <kendo-floatinglabel
                    text="Incorrect Key"
                    class="form-group custom-icon label_height iconpos"
                    formGroupName="sayIncorrectKeyMp3"
                    >
                    <kendo-fileselect
                      [multiple]="false"
                      class="form-group custom-icon help-icon m-0"
                      formControlName="file"
                      [restrictions]="mp3FilesRestriction"
                      (select)="onIncorrectKeyMp3ChangeFile($event)"
                      style="width: 100%"
                      >
                    </kendo-fileselect>
                    <kendo-formerror>WAV upload for Incorrect Key is required, if WAV swich is on</kendo-formerror>
                  </kendo-floatinglabel>
                </kendo-formfield>
                <rg-mp3
                  [state]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.state?.value!"
                  [filename]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.name?.value!"
                  [mp3]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value"
                  style="width: 100%"
                  *ngIf="_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value"
                  >
                </rg-mp3>
                <button
                  kendoButton
                  *ngIf="menuShow && _menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value"
                  class="btn-delete file-delete"
                  (click)="onDeleteIncorrectKeyMp3()"
                  type="button"
                  >
                  <span class="btn-icon fa-duotone fa-trash-can"></span>
                </button>
              </ng-container>
              <kendo-formfield class="k-form-fieldset toggle-fieldset-btn mt-11 align-self-baseline">
                <kendo-label class="form-title" [for]="isSayIncorrectKeyMp3" text="Is Say Mp3?"></kendo-label>
                <kendo-switch
                  formControlName="isSayIncorrectKeyMp3"
                  #isSayIncorrectKeyMp3
                  onLabel="WAV"
                  offLabel="Text"
                ></kendo-switch>
              </kendo-formfield>
            </div>
          </form>
        </div> -->
        <div
          class="col-md-12 toggle-fieldset say-fieldset align-self-start align-items-start k-form relaygrid-form mb-8"
          [formGroup]="_menuForm"
          [@animationShowHide]="menuShow"
          >
          <div class="row w-100">
            <div class="col w-100">
              @if (!_menuForm.get('isSayIncorrectKeyMp3')?.value) {
                <kendo-formfield class="mb-0 pb-0 w-100">
                  <kendo-floatinglabel text="Incorrect Key Press Prompt">
                    <span class="bg-light pb-0 pe-6">
                      <kendo-textbox formControlName="sayIncorrectKey" [spellcheck]="false"> </kendo-textbox>
                      <rgrid-mini-player [_id]="_menuForm.value.sayIncorrectKey_id!" class="mb-1"></rgrid-mini-player>
                    </span>
                  </kendo-floatinglabel>
                </kendo-formfield>
              }
              @if (
                _menuForm.get('isSayIncorrectKeyMp3')?.value &&
                _menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value!
                ) {
                <div
                  >
                  <label>Incorrect Key Press Prompt</label>
                  <rg-mp3
                    [state]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.state?.value!"
                    [filename]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.name?.value!"
                    [mp3]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value!"
                    style="width: 100%"
                    >
                  </rg-mp3>
                </div>
              }
              @if (
                _menuForm.get('isSayIncorrectKeyMp3')?.value &&
                !_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value!
                ) {
                <div
                  >
                  @if (!_menuForm.controls.sayIncorrectKeyMp3?.controls?.url?.value) {
                    <kendo-formfield
                      class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload m-0 mb-10"
                      style="width: 100%"
                      >
                      <kendo-floatinglabel
                        text="Incorrect Key Press Prompt"
                        class="custom-icon iconpos"
                        formGroupName="sayIncorrectKeyMp3"
                        >
                        <kendo-fileselect
                          [multiple]="false"
                          class="form-group custom-icon help-icon m-0"
                          [formControl]="_menuForm.controls.sayIncorrectKeyMp3?.controls?.file!"
                          [restrictions]="mp3FilesRestriction"
                          (select)="onIncorrectKeyMp3ChangeFile($event)"
                          style="width: 100%"
                          >
                        </kendo-fileselect>
                      </kendo-floatinglabel>
                    </kendo-formfield>
                  }
                </div>
              }
            </div>
            @if (
              menuShow &&
              _menuForm.get('isSayIncorrectKeyMp3')?.value &&
              _menuForm.get('sayIncorrectKeyMp3')?.value?.url
              ) {
              <div
                class="col-auto pt-2"
                >
                <br />
                <button (click)="onDeleteIncorrectKeyMp3()" class="border-0 bg-transparent">
                  <span class="fa-duotone fa-trash-can fa-xl"></span>
                </button>
              </div>
            }
            @if (menuShow) {
              <div class="col-auto pt-2">
                <br />
                <kendo-switch
                  formControlName="isSayIncorrectKeyMp3"
                  #isGreetingsMp3
                  onLabel="WAV"
                  offLabel="Text"
                ></kendo-switch>
                <br />
                <br />
              </div>
            }
          </div>
        </div>
        <div
          #greetingText
          class="col-md-12 toggle-fieldset say-fieldset align-self-start align-items-start k-form relaygrid-form mb-8"
          [formGroup]="_menuForm"
          >
          <div class="row w-100">
            <div class="col w-100">
              @if (!_menuForm.get('isGreetingMp3')?.value) {
                <kendo-formfield class="mb-0 pb-0 w-100">
                  <kendo-floatinglabel text="Greeting">
                    <kendo-textarea formControlName="greeting">
                      <textarea spellcheck="false"></textarea>
                      <kendo-textarea-suffix>
                        <rgrid-mini-player
                          [_id]="_menuForm.value._id!"
                          class="m-1"
                          style="width: 97%"
                        ></rgrid-mini-player>
                      </kendo-textarea-suffix>
                    </kendo-textarea>
                  </kendo-floatinglabel>
                  <kendo-formerror>Greeting is required if Text switch is on</kendo-formerror>
                </kendo-formfield>
              }
              @if (_menuForm.get('isGreetingMp3')?.value && _menuForm.controls.mp3?.controls?.url?.value!) {
                <div>
                  <label>Greeting</label>
                  <rg-mp3
                    [state]="_menuForm.controls.mp3?.controls?.state?.value!"
                    [filename]="_menuForm.controls.mp3?.controls?.name?.value!"
                    [mp3]="_menuForm.controls.mp3?.controls?.url?.value!"
                    style="width: 100%"
                    >
                  </rg-mp3>
                </div>
              }
              @if (_menuForm.get('isGreetingMp3')?.value && !_menuForm.controls.mp3?.controls?.url?.value!) {
                <div>
                  @if (!_menuForm.controls.mp3?.controls?.url?.value) {
                    <kendo-formfield
                      class="k-form-fieldset form-group custom-icon help-icon upload-fieldset custom-upload m-0 mb-10"
                      style="width: 100%"
                      >
                      <kendo-floatinglabel text="Greeting" class="custom-icon iconpos" formGroupName="mp3">
                        <kendo-fileselect
                          [multiple]="false"
                          class="form-group custom-icon help-icon m-0"
                          [formControl]="_menuForm.controls.mp3?.controls?.file!"
                          [restrictions]="mp3FilesRestriction"
                          (select)="onMp3ChangeFile($event)"
                          style="width: 100%"
                          >
                        </kendo-fileselect>
                      </kendo-floatinglabel>
                    </kendo-formfield>
                  }
                </div>
              }
            </div>
            @if (menuShow && _menuForm.get('isGreetingMp3')?.value && _menuForm.get('mp3')?.value) {
              <div
                class="col-auto pt-2"
                >
                <br />
                <button (click)="onDeleteGreetingMp3()" class="border-0 bg-transparent">
                  <span class="fa-duotone fa-trash-can fa-xl"></span>
                </button>
              </div>
            }
            @if (menuShow) {
              <div class="col-auto pt-2">
                <br />
                <kendo-switch
                  formControlName="isGreetingMp3"
                  #isGreetingsMp3
                  onLabel="WAV"
                  offLabel="Text"
                ></kendo-switch>
                <br />
              </div>
            }
          </div>
        </div>
        @if (menuShow) {
          <div class="k-form-buttons col-12 mt-0 mb-6 d-flex justify-content-end">
            <button
              kendoButton
              [disabled]="!isFormValid()"
              themeColor="primary"
              class="btn btn-light-primary btn-icon icon-35 align-self-start"
              (click)="saveMenu()"
              data-bs-toggle="collapse"
              data-bs-target="#edit-form"
              >
              <em class="fa-duotone fa-floppy-disks"></em>
              <span class="ms-2 label8">Save</span>
            </button>
            @if (_menuForm.controls._id!.value) {
              <button
                kendoButton
                themeColor="primary"
                class="btn btn-light-danger btn-icon icon-35 align-self-start"
                (click)="onDelete()"
                data-bs-toggle="collapse"
                data-bs-target="#edit-form"
                >
                <em class="fa-duotone fa-trash-can"></em>
                <span class="ms-2 label9">Delete</span>
              </button>
            }
          </div>
        }
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
}
<kendo-tabstrip-tab title=" ">
  <ng-template kendoTabTitle>
    <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
  </ng-template>
  <ng-template kendoTabContent>
    <div class="d-flex justify-content-center m-4 mb-6">
      <div class="d-flex flex-column">
        <p>
          The <em>Menu</em> section allows you to configure the IVR menu's beginning greeting, which is what the
          caller hears when they dial your number. You can also configure what happens when they press an incorrect
          key that is not one of the options provided below 👇. Each prompt can be configured as text-to-speech or by
          uploading your own MP3 file. At least one menu is required so that your customers can call during your
          <a [routerLink]="['/availability']">business hours</a>, and hear your greeting.
        </p>
      </div>
    </div>
  </ng-template>
</kendo-tabstrip-tab>
</kendo-tabstrip>
