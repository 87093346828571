<kendo-tabstrip class="r-tabs mb-4 mt-4 col-md-12 shadow-sm">
  <kendo-tabstrip-tab title="SMS" [selected]="true">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-smile me-1 icon" aria-hidden="true"></em>Preferences
    </ng-template>
    <ng-template kendoTabContent>
      <form class="k-form relaygrid-form row p-8 pt-2 pb-2" [formGroup]="form">
        <kendo-formfield class="k-form-fieldset col-md-6 px-3">
          <kendo-floatinglabel text="Voicemail To #" class="form-group icon">
            <kendo-textbox formControlName="vmailToSms" [clearButton]="true" #workspaceId>
              <ng-template kendoTextBoxPrefixTemplate>
                <em
                  #vmailto
                  (click)="help.emit({ anchor: vmailto, key: 'vmail-to' })"
                  class="ms-3 fs-4 fa-duotone fa-mobile-screen-button"
                  help="true"
                ></em>
              </ng-template>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>To Number is required, for forwarding voicemail notifications to</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="k-form-fieldset col-md-6 px-3">
          <kendo-floatinglabel text="Voicemail To Email" class="form-group icon">
            <kendo-textbox formControlName="vmailToEmail" [clearButton]="true" #taskQueueSid>
              <ng-template kendoTextBoxPrefixTemplate>
                <em
                  #tqId
                  (click)="help.emit({ anchor: tqId, key: 'vmail-to-email' })"
                  help="true"
                  class="ms-3 fs-4 fa-duotone fa-envelope-circle-check"
                ></em>
              </ng-template>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>To email is optional, for forwarding voicemail notifications to</kendo-formerror>
        </kendo-formfield>
      </form>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title=" ">
    <ng-template kendoTabTitle>
      <em class="fa-duotone fa-comment-question me-1 icon fs-4" aria-hidden="true"></em>
    </ng-template>
    <ng-template kendoTabContent>
      <div class="d-flex justify-content-center m-4 mb-6">
        <div class="d-flex flex-column">
          <p>
            Our <em>Voicemail</em> 📼 feature ensures that no customer query goes unheard, even during your busiest
            times or after business hours. When you're unable to take a call, the customer can select an option from
            your IVR to leave a voicemail. You can then listen to the voicemail at your convenience, allowing you to
            respond accurately and promptly. It's like having a personal assistant for your restaurant, helping you
            manage calls and maintain excellent customer service around the clock. Furthermore you can configure the
            system to receive voicemail notifications delivered to your mobile and/or email.
          </p>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
<div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
  <button kendoButton themeColor="primary" class="btn btn-light-primary btn-icon icon-35" (click)="onSave()">
    <em class="fa-duotone fa-floppy-disks"></em>
    <span class="ms-2 label12">Save</span>
  </button>
  <button kendoButton (click)="delete.emit(form)" class="btn btn-light-danger btn-icon icon-35">
    <em class="fa-duotone fa-trash-can"></em>
    <span class="ms-2 label13">Delete</span>
  </button>
</div>
