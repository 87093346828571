import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/assets/config/app-settings';

@Injectable({providedIn: 'root'})
export class ActivityService {
  constructor(private httpClient: HttpClient, private config: AppSettings) { }  
  get(workspaceSid: string) {
    return this.httpClient.get<Activities>(`${this.config.apiBaseUrl}/api/queues/activities?wsid=${workspaceSid}`);
  } 
}

export interface Activity {
  sid: string;
  friendly_name: string;
}

export interface Activities {
  activities: Activity[];
}

