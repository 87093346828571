<form class="k-form relaygrid-form row clearboth mt-2"
  [formGroup]="form">

  <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
    <button kendoButton
      themeColor="primary"
      class="btn btn-light-primary btn-icon icon-35"
      (click)="onSave()">
      <em class="fa-duotone fa-floppy-disks"></em>
    </button>
    <button kendoButton
      class="btn btn-light-danger ms-3 btn-icon icon-35"
      (click)="onDelete()">
      <em class="fa-duotone fa-trash-can"></em>
    </button>
  </div>
</form>
