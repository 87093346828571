@if (playerPosition === 'bottom') {
  <div class="container bg-light p-1">
    <div class="row w-100 m-2">
      <div class="col">
        @if (label) {
          <span><label>{{ label }}</label><br/></span>
        }
        @if (state === fileState.none || state !== fileState.deleted) {
          <span class="fs-7">{{ filename }}</span>
        }
        <i class="ms-3 fa-duotone fa-volume-high"></i>
      </div>
    </div>
    <div class="row w-100">
      <div class="col">
        <rgrid-mini-player [file]="mp3!" class="w-100 mt-0 pt-0"></rgrid-mini-player>
      </div>
    </div>
  </div>
}

<div class="container p-0 m-0">
  @if (playerPosition === 'left') {
    <div class="row border">
      <div class="col" style="width: 50px;">
        <rgrid-mini-player [file]="mp3!" class="ms-5 mt-0 pt-0"></rgrid-mini-player>
      </div>
      <div class="col border">@if (state === fileState.none || state !== fileState.deleted) {
        <span class="fs-7">{{ filename }}</span>
      }</div>
      <div class="col-auto border"><i class="ms-3 fa-duotone fa-volume-high"></i></div>
    </div>
  }
</div>
