import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FileInfo } from '@progress/kendo-angular-upload';
import { RgFormGroup } from '../../models/menu';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  form = new UntypedFormGroup({});
  @Input() set formGroup(value: any) {
    this.form = value;
  }

  @Output() help = new EventEmitter<{ key: string; form: RgFormGroup }>();
  @Output() save = new EventEmitter<RgFormGroup>();
  @Output() delete = new EventEmitter<RgFormGroup>();

  ngOnInit(): void {}

  onSave() {
    this.save.emit(this.form as any);
  }

  onDelete() {
    this.delete.emit(this.form as any);
  }

  public sayMp3Selected(e: FileInfo[]): void {
  }

  public sayByeMp3Selected(e: FileInfo[]): void {
  }

  public infoContent = `
  This menu option will be replayed when caller presses digit {{ digit }}
  `;
  public sayPop = false;
}
