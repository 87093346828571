<div class="custom-popup k-popover">
  <div class="content">
    <div class="k-popover-header">
      <div class="popover-title text-dark" [innerHTML]="context.title | sanitizeHtml"></div>
    </div>
    <div class="k-popover-body">
      @if (context.image) {
        <div class="img-block mb-2">
          <img [src]="context.image" alt="Help" />
        </div>
      }
      <p class="popover-content m-0" [innerHTML]="context.text | sanitizeHtml"></p>
    </div>
    <div class="k-popover-actions">
      <button kendoButton
        class="btn btn-custom">
        <span class="fa-duotone fa-heart me-2"></span>
      </button>
    </div>
  </div>
</div>