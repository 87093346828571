import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FileRestrictions, FileState, SelectEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { triggerAnimationShowHide } from '../../common/expansion.animate';
import { FileForm, Menu, MenuForm } from '../../models/menu';
import { ChangeFileEvent } from '../../models/events';
import { createMenuForm } from '../../services/menu-form.service';
import { AppSettings } from 'src/assets/config/app-settings';
import { SessionService } from 'src/app/core/services/session.service';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [triggerAnimationShowHide],
})
export class MenuComponent {
  @Input() menuForms = new FormArray<FormGroup<MenuForm>>([]);
  @Input() mp3FilesRestriction!: FileRestrictions;
  @Output() menuChange = new EventEmitter<{ _id: string }>();
  @Output() save = new EventEmitter();
  @Output() deleteFile = new EventEmitter<FormGroup<FileForm>>();
  @Output() changeFile = new EventEmitter<ChangeFileEvent>();
  @Output() deleteMenu = new EventEmitter();
  @Output() addMenu = new EventEmitter();
  @Output() help = new EventEmitter<{ anchor: ElementRef | HTMLElement | any, key: string }>();
  @Output() toggleEdit = new EventEmitter<boolean>();
  @Output() menuFormChange = new EventEmitter<FormGroup<MenuForm>>();

  
  protected _menuForm = createMenuForm();
  @Input() set menuForm(value: FormGroup<MenuForm>) {
    if (value) {
      this._menuForm.patchValue(value.value);
      if (!this.menuShow)
        this._menuForm.controls.greeting?.disable();
      this.selectedMenu = value.value as Menu;
    }
  }

  get menuForm(): FormGroup<MenuForm> {
    return this._menuForm;
  }

  constructor(public settings: AppSettings, private session: SessionService, private events: EventsService) { }

  public _menuShow: boolean = false;
  public get menuShow() {
    return this._menuShow;
  }

  public set menuShow(value) {
    this._menuShow = value;

    this._menuShow ? this._menuForm.controls.greeting?.enable()
      : this._menuForm.controls.greeting?.disable();
  }

  public listItems: Array<string> = ['woman', 'man'];
  public selectedMenu: Menu | null = null;

  onMenuChange($event: { _id: string }) {
    this.menuChange.emit($event);
  }

  saveMenu() {
    this.menuFormChange.emit(this._menuForm);
    this.save.emit();
  }

  onMp3ChangeFile(e: SelectEvent) {
    this.changeFile.emit({ selectEvent: e, fileForm: this._menuForm.controls.mp3! });
  }

  onIncorrectKeyMp3ChangeFile(e: SelectEvent) {
    this.changeFile.emit({ selectEvent: e, fileForm: this._menuForm.controls.sayIncorrectKeyMp3! });
  }

  onDeleteGreetingMp3() {
    this.deleteFile.emit(this._menuForm.controls.mp3);
  }

  onDeleteIncorrectKeyMp3() {
    this.deleteFile.emit(this._menuForm.controls.sayIncorrectKeyMp3);
  }

  onDelete() {
    this.deleteMenu.emit();
    this.menuShow = false;
  }

  onAddMenu() {
    this.menuShow = true;
    this.addMenu.emit();
  }

  onCancelAddEdit() {
    this.menuShow = false;
    this.toggleEdit.emit(this.menuShow);
    this.menuChange.emit();
  }

  onToggleEdit() {
    this.menuShow = true;
    this.toggleEdit.emit(this.menuShow);
  }

  public showButton(state: FileState): boolean {
    return state === FileState.Uploaded ? true : false;
  }

  public showButtonerror(state: FileState): boolean {
    return state === FileState.Uploaded ? false : true;
  }

  public remove(upload: UploadComponent, uid: string): void {
    upload.removeFilesByUid(uid);
  }

  public isFormValid(): boolean {
    return (this._menuForm.controls._id?.valid 
      && this._menuForm.controls.name?.valid
      && this._menuForm.controls.greeting?.valid
      && this._menuForm.controls.isGreetingMp3?.valid
      && this._menuForm.controls.isSayIncorrectKeyMp3?.valid
      && this._menuForm.controls.voice?.valid
      && this._menuForm.controls.mp3?.valid
      && this._menuForm.controls.sayIncorrectKey?.valid
      && this._menuForm.controls.sayIncorrectKeyMp3?.valid)!;      
  }
}
