import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AgentGridService } from './services/agent-grid.service';
import { Agent } from './models/agent.model';
import { OptionForm } from 'src/app/menus/models/menu';

const matches = (el: any, selector: any) =>
  (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'queue-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
  providers: [AgentGridService]
})

export class AgentsComponent implements OnInit {
  @ViewChild(GridComponent)
  private grid: GridComponent | undefined;

  unsubscribe = new Subject<void>()
  public _optionForm = new FormGroup<OptionForm>({});
  private queueName: string | undefined;

  @Input() set optionForm(value: FormGroup<OptionForm>) {
    this._optionForm = value ?? new FormGroup<OptionForm>({});
    this.queueName = this._optionForm.controls.friendlyName!.value!;
  }

  @Input() queueSaveClicked: Observable<void> | undefined;

  public formGroup: FormGroup | undefined;
  private editedRowIndex: number | undefined;
  private docClickSubscription: Subscription = new Subscription();
  private isNew: boolean | undefined;

  constructor(public gridService: AgentGridService, private renderer: Renderer2) { }

  public ngOnInit(): void {
    const queue = this._optionForm.controls.friendlyName!.value!;

    this.gridService.fetch(queue)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    this.docClickSubscription.add(this.renderer.listen('document', 'click', this.onDocumentClick.bind(this)));

    this.queueSaveClicked?.subscribe(() => {
      this.gridService.create(this.queueName!)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public addHandler(): void {
    this.closeEditor();

    this.formGroup = this.createFormGroup(new Agent());
    this.isNew = true;

    this.grid!.addRow(this.formGroup);
  }

  public removeHandler($e: RemoveEvent): void {
    this.gridService.remove($e.dataItem);
  }

  public saveRow(): void {
    if (this.formGroup && this.formGroup.valid) {
      this.saveCurrent();
    }
  }

  public cellClickHandler($e: CellClickEvent): void {
    if ($e.isEdited || $e.columnIndex === 3 || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    if (this.isNew) {
      $e.rowIndex += 1;
    }

    this.saveCurrent();

    this.formGroup = this.createFormGroup($e.dataItem);
    this.editedRowIndex = $e.rowIndex;

    this.grid!.editRow($e.rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid!.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private onDocumentClick(e: Event): void {
    if (
      this.formGroup &&
      this.formGroup.valid &&
      !matches(e.target, '#productsGrid tbody *, #productsGrid .k-grid-toolbar .k-button')
    ) {
      this.saveCurrent();
    }
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      this.gridService.save(this.formGroup.value, this.isNew!);
      this.closeEditor();
    }
  }

  private createFormGroup = (dataItem: any) =>
    new FormGroup({
      originalInterface: new FormControl(dataItem.originalInterface),
      queue_name: new FormControl(dataItem.queue_name || this._optionForm.controls.friendlyName!.value, Validators.required),
      interface: new FormControl(dataItem.interface, [Validators.required, Validators.maxLength(10)]),
      membername: new FormControl(dataItem.membername, Validators.required),
      available: new FormControl(dataItem.available)
    });
}
