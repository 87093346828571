import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/assets/config/app-settings';


@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private settings: AppSettings) { }

  upload(customerId: string, file: Blob, type: string, filepath = '') {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file, (file as any).name);
    formData.append('filepath', filepath);
    return this.http.post<UploadResponse>(`${this.settings.apiBaseUrl}/api/blob/upload/${customerId}/${type}`, formData, { headers });
  }

  delete(filename: string) {
    const options = { body: { filename } };
    return this.http.delete(`${this.settings.apiBaseUrl}/api/blob/delete`, options);
  }
}



export interface UploadResponse {
  code?: number;
  error?: boolean;
  message?: string;
  results?: {
    link?: string;
  }  
}