<div class="mini-player w-100">
  <div class="control-icons d-flex align-items-center ms-1">
    <!-- progress: {{ progress }}<br/> -->
    <button class="border-0 bg-transparent pe-0" (click)="rewindAudio()" [disabled]="progress == 0">
      <i class="fa-duotone fa-backward pt-1 pb-1"></i>
    </button>
    @if (!isPlaying) {
      <button class="border-0 bg-transparent" (click)="togglePlay()">
        <i class="fa-duotone fa-play pt-1 pb-1"></i>
      </button>
    }
    @if (isPlaying) {
      <button class="border-0 bg-transparent" (click)="togglePlay()">
        <i class="fa-duotone fa-pause pt-1 pb-1"></i>
      </button>
    }
  </div>
  <div class="progress" style="flex-grow: 1" role="progressbar" aria-label="Audio progress" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100">
    <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" aria-label="Progress bar">
    </div>
  </div>
</div>
