import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionForm } from '../../models/menu';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: []
})
export class LinkComponent {
  @Output() save = new EventEmitter<FormGroup<OptionForm>>();
  @Output() delete = new EventEmitter<FormGroup<OptionForm>>();

  form = new FormGroup<OptionForm>({});
  @Input() set formGroup(value: FormGroup<OptionForm>) {
    this.form = value;
  }

  @Input() menus: any[] = [];

  getMenuName(): string {
    const id = this.form.controls.menu?.value;
    const menu = this.menus.find(m => {
      const match = m._id === id;
      return match;
    });
    return menu?.name ?? 'no menu selected yet';
  }
}
